import { Box, useTheme } from '@mui/material';
import { Pie, PieChart as RechartsPieChart, Tooltip } from 'recharts';

const CustomTooltip = ({ active, payload }: any) => {
	const theme = useTheme()

	if (active && payload && payload.length && payload[0].name) {
		return <Box sx={{
			position: 'relative',
			zIndex: 1101,
			color: theme.palette.text.secondary,
			fontSize: '14px',
			fontWeight: 500,
			lineHeight: '18px',
			background: theme.palette.secondary.main,
			padding: '4px 8px',
			border: `1px solid ${theme.palette.secondary.main}`,
			borderRadius: '4px',
			width: 'max-content',
			textAlign: 'center',
			'&::before': {
				zIndex: 1101,
				content: '" "',
				position: 'absolute',
				bottom: '-8px',
				left: '90px',
				width: '10px',
				height: '10px',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center',
				backgroundSize: '10px',
				backgroundImage: `url('/arrow.svg')`,
				transform: 'rotate(180deg)'
			},
		}}>
			{payload[0].name}
		</Box>
	}
	return null
}

type Props = {
	size: number
	data: any[]
}

export default function PieChart({ data, size }: Props) {
	return (<Box sx={{ '& > * > div.recharts-tooltip-wrapper': { zIndex: 1101 }}}>
		<RechartsPieChart width={size} height={size}>
		<Pie data={data}
			dataKey="value"
			cx="50%"
			cy="50%"
			outerRadius={size / 2}
			stroke='none'
			cursor={'pointer'}
		/>
		<Tooltip position={{ x: -80, y: -40 }} content={CustomTooltip} />
	</RechartsPieChart>
	</Box>)
}