import { Box, Typography } from "@mui/material";

export default function Footer() {
    return (<Box sx={{ position: 'relative' }}>
        <Box sx={{
            position: 'relative',
            zIndex: 2,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            height: ['200px', '442px'],
            objectFit: 'cover',
            background: `url(/footer.svg) no-repeat`,
            backgroundSize: '100% auto',
        }}>
            <Typography sx={{
                fontFamily: 'Epilogue',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '20px',
                marginTop: ['50px', '150px']
            }}>Prisma Finance 2024</Typography>
        </Box>
    </Box>)
}