import { Box, Grid, useTheme } from '@mui/material';
import MainHeading from '../components/MainHeading';
import HowItWorks from '../components/HowItWorks';
import LiquidStakingTokens from '../components/LiquidStakingTokens';
import FamilyAndFriends from '../components/FamilyAndFriends';
import Values from '../components/Values';
import FAQ from '../components/FAQ';
import Social from '../components/Social';
import Footer from '../components/Footer';
import PlatformStats from '../components/PlatformStats';
import CollateralSelection from '../components/CollateralSelection';
import GetStarted from '../components/GetStarted';
import PrismaLRT from '../components/PrismaLRT';

const containerStyle = {
	margin: 'auto',
	width: ['100%', '1200px'],
	paddingX: '5vw'
}

export default function LandingPage() {
    const theme = useTheme()

	return (<Box sx={{ marginTop: '200px' }}>
		<Grid container rowSpacing={'24px'} sx={{ zIndex: 1, position: 'relative' }}>
			<Grid item lg={12} md={12} sm={12} xs={12}>
				<Box sx={containerStyle}>
					<MainHeading />
				</Box>
			</Grid>
			<Grid item lg={12} md={12} sm={12} xs={12} sx={{
				position: 'relative',
				paddingBottom: ['40px !important', '100px !important'],
			}}>
				<Box sx={containerStyle}>
					<PlatformStats />
				</Box>
			</Grid>
			<Grid item id='prisma-lrt' lg={12} md={12} sm={12} xs={12} sx={{
				position: 'relative',
				paddingBottom: ['30px !important', '230px !important'],
				'&::before': {
					content: '" "',
					position: 'absolute',
					bottom: ['-52%', '-35%'],
					width: '100vw',
					height: '100%',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					backgroundSize: '100% auto',
					backgroundImage: `url('/divider-graphics/header.svg')`,
				},
				background: 'linear-gradient(180deg, rgba(85, 90, 206, 0.35) 0%, rgba(85, 90, 206, 0) 86.97%)'
			}}>
				<Box sx={containerStyle}>
					<PrismaLRT />
				</Box>
			</Grid>
			<Grid item id='how-it-works' lg={12} md={12} sm={12} xs={12} sx={{
				paddingTop: ['50px !important']
			}}>
				<Box sx={containerStyle}>
					<HowItWorks />
				</Box>
			</Grid>
			<Grid item id='collaterals' lg={12} md={12} sm={12} xs={12} sx={{
				paddingTop: '150px !important',
				position: 'relative',
				'&::before': {
					zIndex: 1,
					content: '" "',
					position: 'absolute',
					bottom: ['-7.5%', '-30%'],
					width: '100vw',
					height: '141px',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					backgroundSize: '100% auto',
					backgroundImage: `url('/divider-graphics/collateral.svg')`,
				},
			}}>
				<Box sx={containerStyle}>
					<CollateralSelection />
				</Box>
			</Grid>
			<Grid item id='lsts' lg={12} md={12} sm={12} xs={12} sx={{
				paddingTop: ['150px !important', '300px !important'],
				position: 'relative',
				'&::before': {
					zIndex: 1,
					content: '" "',
					position: 'absolute',
					bottom: '-5%',
					width: '100vw',
					height: '1px',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					backgroundSize: '100% auto',
					backgroundImage: `url('/divider-graphics/lst.svg')`,
				},
			}}>
				<Box sx={containerStyle}>
					<LiquidStakingTokens />
				</Box>
			</Grid>
			<Grid item id='the-fam' lg={12} md={12} sm={12} xs={12} sx={{
				paddingTop: '120px !important',
				position: 'relative',
				'&::before': {
					zIndex: 1,
					content: '" "',
					position: 'absolute',
					bottom: ['-20%', '-50%'],
					width: '100vw',
					height: '335px',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					backgroundSize: '100% auto',
					backgroundImage: `url('/divider-graphics/friends.svg')`,
				},
			}}>
				<Box sx={containerStyle}>
					<FamilyAndFriends />
				</Box>
			</Grid>
			<Grid item id='values' lg={12} md={12} sm={12} xs={12} sx={{ paddingTop: '250px !important'}}>
				<Box sx={containerStyle}>
					<Values />
				</Box>
			</Grid>
			<Grid item id='FAQ' lg={12} md={12} sm={12} xs={12} sx={{ paddingTop: '150px !important', paddingBottom: '50px'}}>
				<Box sx={containerStyle}>
					<FAQ />
				</Box>
			</Grid>
			<Grid item id='get-started' lg={12} md={12} sm={12} xs={12} sx={{
				paddingTop: '150px !important',
				position: 'relative',
				height: '445px',
				'&::before': {
					content: '" "',
					position: 'absolute',
					bottom: '0',
					width: '100vw',
					height: '442px',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					backgroundImage: `url('/get-started.svg')`,
				},
			}}>
				<Box sx={{...containerStyle }}>
					<GetStarted />
				</Box>
			</Grid>
			<Grid item id='social' lg={12} md={12} sm={12} xs={12} sx={{ paddingTop: '150px !important'}}>
				<Box sx={containerStyle}>
					<Social />
				</Box>
			</Grid>
			<Grid item id='footer' lg={12} md={12} sm={12} xs={12} sx={{ paddingTop: '80px !important' }}>
				<Box sx={containerStyle}>
					<Footer />
				</Box>
			</Grid>
		</Grid>
	</Box>)
}