import { ReactNode } from 'react';
import { Tooltip, useTheme } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type Props = {
    info: string | ReactNode
}

function InfoTooltip({ info }: Props) {
    const theme = useTheme()
    return (<Tooltip arrow placement="top" title={info}>
        <InfoOutlinedIcon sx={{ color: theme.palette.tertiaryText.main }} />
    </Tooltip>)
}

export default InfoTooltip;

