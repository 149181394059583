import { Box, Card, CardContent, Grid, Link, Typography, Tooltip } from "@mui/material";
import { ReactComponent as ImmutableIcon } from './../values/immutable.svg'
import { ReactComponent as ProxyIcon } from './../values/proxy.svg'
import { AUDIT1, AUDIT2, AUDIT3 } from "../globals";
import { ReactComponent as MixBytesIcon } from './../MixBytes-logo-white.svg'
import { ReactComponent as ZellicIcon } from './../Zellic-logo-white.svg'
import { ReactComponent as NomoiIcon } from './../Nomoi-logo-white.svg'
import { ReactComponent as LockIcon } from './../graphics/lock.svg'

const valueTitle = {
    fontFamily: 'Epilogue',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '28px',
    color: '#FFFFFF',
}

export default function Values() {
    return (<Grid container rowSpacing={'38px'} columnSpacing={'26px'}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '12px' }}>
                <LockIcon />
            </Box>
            <Typography sx={{
                fontFamily: 'Epilogue',
                fontSize: '32px',
                fontWeight: 700,
                lineHeight: '38px',
                textAlign: 'center',
                marginBottom: '15px'
            }}>
                Audited and verified
            </Typography>
            <Typography sx={{
                fontSize: '20px',
                fontWeight: 400,
                lineHeight: '30px',
                textAlign: 'center',
            }}>
                Prisma's codebase has received multiple audits from top firms.<br /> Our codebase is immutable and without proxies, <b>as DeFi should be.</b>
            </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
            <Card sx={{ background: '#171719', height: '250px', }}>
                <CardContent sx={{
                    padding: '24px 24px',
                    paddingBottom: '24px !important',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Typography sx={{...valueTitle, marginBottom: '40px' }}>No Proxy<br />Contracts</Typography>
                    <ProxyIcon />
                </CardContent>
            </Card>
        </Grid> 
        <Grid item lg={6} md={6} sm={12} xs={12}>
            <Card sx={{ background: '#171719', height: '250px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <CardContent sx={{ padding: '24px 24px', paddingBottom: '24px !important' }}>
                    <Typography sx={{
                        fontFamily: 'Epilogue',
                        fontSize: '20px',
                        fontWeight: 500,
                        lineHeight: '24px',
                        textAlign: 'center',
                        marginBottom: '16px'
                    }}>Audited By:</Typography>
                    <Grid container columnSpacing={'12px'}>
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Link href={AUDIT1} target="__blank" rel="noreferrer">
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', border: '2px dashed #454546', height: '64px'}}>
                                    <MixBytesIcon height='20px' />
                                </Box>
                            </Link>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Link href={AUDIT2} target="__blank" rel="noreferrer">
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', border: '2px dashed #454546', height: '64px'}}>
                                    <ZellicIcon height='20px' />
                                </Box>
                            </Link>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Link href={AUDIT3} target="__blank" rel="noreferrer">
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', border: '2px dashed #454546', height: '64px'}}>
                                    <NomoiIcon height='20px' />
                                </Box>
                            </Link>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
            <Card sx={{ background: '#171719', height: '250px' }}>
                <CardContent sx={{
                    padding: '24px 24px',
                    paddingBottom: '24px !important',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Typography sx={{...valueTitle, marginBottom: '40px' }}>Immutable</Typography>
                    <ImmutableIcon />
                </CardContent>
            </Card>
        </Grid>
    </Grid>)
}