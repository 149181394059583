import numeral from "numeral";

type Props = {
    value: number
    precision?: number
}

export function DisplayNumber({ value, precision = 1 }: Props) {
    return (<>{value !== null ? numeral(value).format('0,0.' + '0'.repeat(precision)) : '-'}</>)
}
