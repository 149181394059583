import { Box, Slider as MuiSlider, SliderValueLabelProps, Tooltip, Typography, styled, useTheme } from "@mui/material"

function ValueLabelComponent(props: SliderValueLabelProps) {
	const { children, value } = props;

	return (
		<Tooltip enterTouchDelay={0} placement="top" arrow title={value}>
			{children}
		</Tooltip>
	)
}

const StyledSlider = styled(MuiSlider)`
    *[class*='MuiSlider-mark'][data-index='0'] {
        height: 18px;
        opacity: 1;
    }

    *[class*='MuiSlider-mark'][data-index='${({ marks }) =>
		marks && !(typeof (marks) === 'boolean') && marks.length > 0 ? marks.length - 1 : 0
	}'] {
        height: 18px;
        opacity: 1;
    }
`;

const DEFAULT_MARKS = [
	{
		value: 120,
		label: '120',
	},
	{
		value: 150,
		label: '150',
	},
	{
		value: 200,
		label: '200',
	},
	{
		value: 250,
		label: '250',
	},
]

type Props = {
	title: string
	i: number
	change: (i: number) => void
}

export default function Slider({ title, i, change }: Props) {
	const theme = useTheme()

	const handleChange = (event: any) => {
		change(event.target.value)
	}

	return (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
		<Typography sx={{
			textAlign: 'left',
			paddingY: '4px',
			fontSize: '14px',
			lineHeight: '18px',
			fontWeight: 500
		}}>
			{title}
		</Typography>
		<StyledSlider
			sx={{
				borderRadius: 'unset',
				color: '#EDEFF1',
				'& > .Mui-focusVisible': {
					boxShadow: `0px 0px 0px 4px rgba(49, 110, 255, 0.2) !important`,
				},
				'.MuiSlider-rail': {
					height: '2px',
					color: '#EDEFF1'
				},
				'.MuiSlider-track': {
					border: 'unset',
					height: '3px',
					color: '#EDEFF1'
				},
				'.MuiSlider-mark': {
					opacity: 0.5,
					//color: theme.palette.primary.main,
					//backgroundColor: theme.palette.primary.main,
					color: '#C4C4C4',
					backgroundColor: '#C4C4C4',
					height: '10px',
				},
				'.MuiSlider-markActive': {
					opacity: 1,
				},
				'.MuiSlider-markLabel': {
					color: theme.palette.text.primary,
					fontSize: '12px',
					lineHeight: '14px',
					fontWeight: 400
				},
				'.MuiSlider-thumb': {
					height: '22px',
					width: '22px',
					color: theme.palette.primary.main,
					boxShadow: `0px 0px 0px 4px rgba(49, 110, 255, 0.2)`,
					'&:hover': {
						boxShadow: `0px 0px 0px 4px rgba(49, 110, 255, 0.2) !important`,
					}
				}
			}}
			value={i}
			onChange={handleChange}
			aria-label="Slider"
			defaultValue={120}
			getAriaValueText={(value: number) => `CR ${value}`}
			valueLabelDisplay="off"
			step={10}
			min={120}
			max={250}
			marks={DEFAULT_MARKS}
			slots={{
				valueLabel: ValueLabelComponent,
			}}
		/>
	</Box>)
}