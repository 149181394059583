import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
	AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const CustomAccordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	//border: `1px solid ${theme.palette.divider}`,
	// expand towards bottom
	display: 'flex',
	flexDirection: 'column',
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		//expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	width: 'fit-content',
	padding: 'unset',
	minHeight: 'unset',
	textAlign: 'left',
	backgroundColor: 'inherit',
	flexDirection: 'column',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		margin: 'unset',
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: '22px 36px',
	borderBottomLeftRadius: '12px',
	borderBottomRight: '12px',
}));

type Props = {
	panels: {
		openTitle: React.ReactNode | string
		closeTitle: React.ReactNode | string
		content: React.ReactNode | string
	}[]
}

export default function Accordion({ panels }: Props) {
	const [expanded, setExpanded] = React.useState<string | false>('panel0')

	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			event.preventDefault()
			event.stopPropagation()
			setExpanded(newExpanded ? panel : false);
		}

	return (
		<>
			{panels.map((p, key) =>
				<CustomAccordion
					key={key}
					defaultExpanded={false}
					expanded={expanded === `panel${key}`}
					onChange={handleChange(`panel${key}`)}
					sx={{
						borderRadius: '12px',
						marginBottom: key === panels.length-1 ? 'unset' : '12px',
						background: 'linear-gradient(270deg, rgba(37, 37, 42, 0.20) 0%, #25252A 98.96%)',
					}}
				>
					<AccordionSummary
						sx={{
							borderRadius: '12px',
							width: '100%',
							padding: '22px 36px',
							background: 'linear-gradient(270deg, rgba(37, 37, 42, 0.20) 0%, #25252A 98.96%)',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
						aria-controls={`panel${key}d-content`} id={`panel${key}d-header`}>
						{typeof (p.content) === 'string' ?
							<Typography>{expanded === `panel${key}` ? p.closeTitle : p.openTitle}</Typography> :
							expanded === `panel${key}` ? p.closeTitle : p.openTitle
						}
					</AccordionSummary>
					<AccordionDetails>
						{typeof (p.content) === 'string' ? <Typography>{p.content}</Typography> : p.content}
					</AccordionDetails>
				</CustomAccordion>
			)}
		</>
	)
}