import { Box, Link, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export function FAQLink() {
    return <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <HelpOutlineIcon sx={{ color: '#2D64E7', fontWeight: 400, marginBottom: '8px' }} />
        <Typography sx={{
            fontFamily: 'Epilogue',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            color: '#2D64E7',
            textAlign: 'center'
        }}>
            Still a bit lost?<br />
            <Link href={'#FAQ'}>
                Read more in our FAQ
            </Link>
        </Typography>
    </Box>
}
