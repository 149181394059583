import { Box, Grid, Typography } from "@mui/material";
import CurveIcon from './../friends/curve.png'
import { ReactComponent as ConvexIcon } from './../friends/convex.svg'
import { ReactComponent as FraxIcon } from './../friends/frax.svg'
import { ReactComponent as LlamaNodesIcon } from './../friends/llamanodes.svg'
import { ReactComponent as ConicIcon } from './../friends/conic.svg'
import { ReactComponent as CoingeckoIcon } from './../friends/coingecko.svg'
import { ReactComponent as LayerZeroIcon } from './../friends/layerzero.svg'

export default function FamilyAndFriends() {

    const friends = [
        {
            graphic: <img src={CurveIcon} height='48px' />,
            link: ''
        },
        {
            graphic: <ConvexIcon height='30px' />,
            link: ''
        },
        {
            graphic: <FraxIcon height='57px' />,
            link: ''
        },
        {
            graphic: <LlamaNodesIcon height='32px' />,
            link: ''
        },
        {
            graphic: <CoingeckoIcon height='53px' />,
            link: ''
        },
        {
            graphic: <LayerZeroIcon height='56px' />,
            link: ''
        },
    ]

    return (<>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginBottom: '68px'
        }}>
            <Typography sx={{
                fontFamily: 'Epilogue',
                fontSize: '32px',
                fontWeight: 700,
                lineHeight: '38px',
                color: '#FFFFFF',
                textAlign: 'center',
                marginBottom: '13px'
             }}>
                Family & Friends
            </Typography>
            <Typography sx={{
                fontSize: '20px',
                fontWeight: 300,
                lineHeight: '30px',
                textAlign: 'center',
             }}>
                Backed and supported by most of <b>Defi's finest</b>.
            </Typography>
        </Box>
        <Grid container rowSpacing={'48px'} sx={{ justifyContent: 'center' }}>
            {friends.map((f, key) =>
                <Grid key={key} item lg={4} md={4} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px',
                        width: '300px',
                        borderRadius: '12px',
                        background: 'linear-gradient(270deg, rgba(37, 37, 42, 0.20) 0%, rgba(37, 37, 42, 0.04) 98.96%)',
                        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10)'
                    }}>
                        {f.graphic}
                    </Box>
                </Grid>
            )}
        </Grid>
    </>)
}