import { Box, Typography } from "@mui/material";
import OpenApp from "./OpenApp";

export default function GetStarted() {
    return (<Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    }}>
        <Typography sx={{
            fontFamily: 'Epilogue',
            fontWeight: 500,
            fontSize: '64px',
            lineHeight: '76px',
            letterSpacing: '0.6px',
            background: 'linear-gradient(249deg, #FFF 46.69%, #0D0D0E 158.28%)',
            backgroundClip: 'text',
            color: 'transparent',
        }}>
            Get started<br /> with Prisma
        </Typography>
        <OpenApp />
    </Box>)
}