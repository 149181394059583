import { Box, CssBaseline } from '@mui/material';
import './App.css';
import LandingPage from './pages/LandingPage';
import MainAppBar from './components/AppBar';
import { LandingPageProvider } from './api/useLandingPageContext';

function App() {
	return (
		<LandingPageProvider>
		<div className="App" id='home'>
			<Box sx={{
				height: '962px',
				width: ['100%', '1640px'],
				margin: 'auto',
				position: 'absolute',
				left: 0,
				right: 0,
			}}>
				<Box sx={{
					zIndex: 1,
					height: '100%',
					width: '100%',
					position: 'relative',
					objectFit: 'cover',
					background: `url(/header2.svg) no-repeat`,
					backgroundSize: '100% auto',
					margin: 'auto',
				}} />
			</Box>
			<CssBaseline />
			<Box sx={{
				display: 'flex',
				position: 'relative',
			 }}>
				<MainAppBar />
				<Box component="main" sx={{ flexGrow: 1 }}>
					<LandingPage />
				</Box>
			</Box>
			
		</div>
		</LandingPageProvider>
	)
}

export default App;
