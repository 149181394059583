import { Box, Button, Card, CardContent, Grid, Link, Typography } from "@mui/material";
import { ReactComponent as TwitterIcon } from './../socials/twitter.svg'
import { ReactComponent as DocsIcon } from './../socials/docs.svg'
import { ReactComponent as GithubIcon } from './../socials/github.svg'
import { DISCORD, DOCS_DOMAIN, GITHUB, TWITTER } from "../globals";

const title = {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '22px',
    color: '#FFFFFF',
    opacity: 0.8
}

export default function Social() {

    const socials = [
        {
            graphic: <TwitterIcon />,
            title: 'Twitter',
            link: TWITTER
        },
        {
            graphic: <DocsIcon />,
            title: 'Docs',
            link: DOCS_DOMAIN
        },
        {
            graphic: <GithubIcon />,
            title: 'Github',
            link: GITHUB
        }, 
    ]

    return (<Grid container rowSpacing={'30px'} columnSpacing={'32px'}>
        <Grid item lg={3} md={3} sm={12} xs={12}>
            {socials.map((s, key) =>
                <Card key={key} sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '24px',
                    paddingY: '12px',
                    background: 'linear-gradient(270deg, #25252A 0%, rgba(37, 37, 42, 0.20) 98.96%)',
                    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10)',
                }}>
                    <CardContent sx={{
                        paddingX: '64px',
                    }}>
                        <Link href={s.link} target="__blank" rel="noreferrer" sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}>
                            {s.graphic}
                            <Typography sx={{...title, marginLeft: '24px' }}>
                                {s.title}
                            </Typography>
                        </Link>
                    </CardContent>
                </Card>
            )}
        </Grid>
        <Grid item lg={9} md={9} sm={12} xs={12} sx={{ position: 'relative' }}>
            <Box sx={{ positon: 'relative' }}>
                <Card sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '24px',
                    paddingTop: '44px',
                    paddingBottom: '24px',
                    background: 'linear-gradient(270deg, rgba(37, 37, 42, 0.20) 0%, rgba(37, 37, 42, 0.04) 98.96%)',
                    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10)',
                    zIndex: 1,
                    '&::before': {
                        visibility: ['hidden', 'visible'],
                        transition: 'all 0.5s ease',
                        zIndex: 2,
                        content: '" "',
                        position: 'absolute',
                        right: '65px',
                        bottom: '7px',
                        width: '308px',
                        height: '255px',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: '308px',
                        backgroundImage: `url('/discord.svg')`,
                    },
                }}>
                    <CardContent sx={{
                        paddingX: '24px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: ['center', 'flex-start']
                    }}>
                        <Typography sx={{
                            fontFamily: 'Epilogue',
                            fontSize: '32px',
                            fontWeight: 500,
                            lineHeight: '38px',
                            textAlign: 'left',
                            marginBottom: '22px',
                        }}>
                            Prisma Discord
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Epilogue',
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            color: '#FFFFFF',
                            textAlign: 'left',
                            marginBottom: '12px',
                            display: ['none', 'block']
                        }}>
                            Have a question or want to contribute?<br />
                            Join the Prisma community on Discord.
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Epilogue',
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            color: '#FFFFFF',
                            marginBottom: '12px',
                            textAlign: 'center',
                            display: ['block', 'none']
                        }}>
                            Have a question or want to contribute?
                            Join the Prisma community on Discord.
                        </Typography>
                        <Button
                            href={DISCORD}
                            target="__blank"
                            rel="noreferrer"
                            variant='blue'
                            sx={{ width: '200px' }}
                        >
                            Open Discord
                        </Button>
                    </CardContent>
                </Card>
            </Box>
        </Grid>
    </Grid>)
}