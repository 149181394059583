import { Box, Card, CardContent, Grid, Link, Typography } from "@mui/material";
import { DisplayShortNumber } from "./DisplayShortNumber";
import { Divider } from "./Divider";
import { ReactComponent as EtherIcon } from './../ether-icon.svg'
import { ReactComponent as StakedEtherIcon } from './../staked-ether-icon.svg'
import { ReactComponent as SizeStakedEtherIcon } from './../size-staked-ether-icon.svg'
import { ReactComponent as EtherPie } from './../ether-pie.svg'
import { useLandingPageContext } from "../api/useLandingPageContext";
import { FAQLink } from "./FAQLink";

const stat = {
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '44px',
    color: '#FFFFFF'
}

const title = {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    color: '#FFFFFF',
    opacity: 0.8
}

export default function LiquidStakingTokens() {

    const { etherApr, etherTotalStaked, etherTotalStakedUSD } = useLandingPageContext()

    const stats = [
        {
            graphic: <StakedEtherIcon />,
            padding: '10px 2px 10px 10px',
            title: 'Staked ETH APR',
            stat: <><DisplayShortNumber value={etherApr} />%</>
        },
        {
            graphic: <EtherIcon />,
            padding: '11px 12px',
            title: 'Total Ether Staked',
            stat: <><DisplayShortNumber value={etherTotalStaked} /></>
        },
        {
            graphic: <SizeStakedEtherIcon />,
            padding: '14.5px 5.5px',
            title: 'Size of Staked Ether',
            stat: <>$<DisplayShortNumber value={etherTotalStakedUSD} /></>
        }, 
    ]

    return (<Grid container rowSpacing={'30px'} columnSpacing={'32px'}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography sx={{
                fontFamily: 'Epilogue',
                fontSize: '32px',
                fontWeight: 300,
                lineHeight: '38px',
                marginBottom: '34px'
             }}>
                Take advantage of Ethereum’s proof of stake network and unlock loans where the collateral grows.
            </Typography>
            <Typography sx={{
                fontSize: '20px',
                fontWeight: 300,
                lineHeight: '30px',
                marginBottom: '18px'
             }}>
                Since Ethereum’s transition to proof-of-stake, staked Ether has been an attractive yield opportunity.
            </Typography>
            <Typography sx={{
                fontSize: '20px',
                fontWeight: 300,
                lineHeight: '30px',
                marginBottom: '64px'
             }}> 
                Using liquid representations of staked Ether as collateral for loans means that your collateral is growing all the time.
            </Typography>
            
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
            <Grid container rowSpacing={'30px'} columnSpacing={'50px'}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    {stats.map((s, key) =>
                        <Card key={key} sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginBottom: '24px',
                            background: 'linear-gradient(218deg, #25252A 0%, rgba(37, 37, 42, 0.20) 98.96%)',
                        }}>
                            <CardContent sx={{ padding: '12px 12px' }}>
                                <Card sx={{ padding: s.padding }}>
                                    <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                                        {s.graphic}
                                    </CardContent>
                                </Card>
                            </CardContent>
                            <CardContent sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}>
                                <Typography sx={stat}>
                                    {s.stat}
                                </Typography>
                                <Typography sx={title}>
                                    {s.title}
                                </Typography>
                            </CardContent>
                            
                        </Card>
                    )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box sx={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            '& > svg': {
                                margin: 'auto'
                            },
                            marginBottom: '48px'
                        }}>
                            <EtherPie width='200px' height='200px' />
                        </Box>
                        <Box sx={{
                            position: 'relative',
                            margin: 'auto',
                            width: 'fit-content',
                            padding: '12px 16px',
                            background: '#25252A',
                            borderRadius: '12px',
                            '&::before': {
                                zIndex: 1,
                                content: '" "',
                                position: 'absolute',
                                top: '-20px',
                                left: '95px',
                                width: '25px',
                                height: '25px',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundSize: '25px',
                                backgroundImage: `url('/arrow.svg')`,
                            },
                        }}>
                            <Typography sx={{
                               fontSize: '22px',
                               fontWeight: 700,
                               lineHeight: '34px',
                               color: '#FFFFFF',
                               opacity: 0.8
                            }}>Staked ETH v LST</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <FAQLink />
        </Grid>
    </Grid>)
}