import { Box, Typography, useTheme } from "@mui/material"

type Props = {
    trancheType: number
}

export default function TrancheTypeIndicator({ trancheType }: Props) {
    const theme = useTheme()
    return trancheType === 2 || trancheType == 3 ? <Box sx={{
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '6px',
        paddingX: '4px',
        width: '23px',
        height: '23px'
    }}>
        <Typography sx={{
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '20px',
            color: theme.palette.primary.main
        }}>
            {trancheType === 2 ? 'A' : 'B'}
        </Typography>
    </Box> : <></>
}