import { ReactNode } from 'react';
import { Box, Tooltip, Typography, useTheme } from '@mui/material';

type InfoProps = {
    infoTitle: string
    value: ReactNode
    lastItem: boolean
    help: string | undefined
}

function Info({ infoTitle, value, lastItem, help }: InfoProps) {
    const theme = useTheme()

    const textStyling = {
        color: theme.palette.text.primary,
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '18px',
        '&:hover': {
            cursor: help ? 'pointer' : 'inherit'
        }
    }

    const statStyling = {
        color: theme.palette.text.primary,
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '18px'
    }

    return <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: lastItem ? 'unset' : '1px solid rgba(0, 34, 55, 0.1)',
        paddingTop: '5px',
        paddingBottom: lastItem ? 'unset' : '5px'
    }}>
        <Tooltip arrow placement="bottom" title={help}>
            <Typography sx={textStyling}>{infoTitle}</Typography>
        </Tooltip>
        <Typography sx={statStyling}>{value}</Typography>
    </Box>
}

type Props = {
    stats: { help: string | undefined, title: string | undefined, value: ReactNode }[]
}

function CollateralStats({ stats }: Props) {
    const theme = useTheme()
    return (<Box sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingX: '12px',
        paddingTop: '20px',
        paddingBottom: '8px',
        background: theme.palette.cardTertiaryBg.main,
        borderTop: '1px solid transparent',
        borderImage: theme.palette.rainbow.main,
        borderImageSlice: 1
    }}>
        {stats.map((s, k) => (<span key={k}>
            {s.title ? <Info
                key={k}
                infoTitle={s.title}
                value={s.value}
                help={s.help}
                lastItem={k === (stats.length-1)}
            /> : <></>}</span>
        ))}
    </Box>)
}

export default CollateralStats;
