import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useLandingPageContext } from "../api/useLandingPageContext";
import TokenIcon from "./TokenIcon";
import { CONFIG } from "../globals";
import { DisplayNumber } from "./DisplayNumber";
import { DisplayShortNumber } from "./DisplayShortNumber";

const stat = {
    fontSize: '24px',
    fontWeight: 'normal',
    lineHeight: '24px',
    color: '#FFFFFF'
}

const title = {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '28px',
    color: '#FFFFFF',
}

export default function PlatformStats() {
    const { collateralTvl, totalDebt, totalUltraDebt, prismaLocked, prismaLockedPct } = useLandingPageContext()

    const stats = [
        {
            graphic: <TokenIcon address={CONFIG.addresses.DebtToken} width='28' />,
            padding: '10px 2px 10px 10px',
            title: 'mkUSD circulating',
            stat: <><DisplayNumber value={totalDebt} /></>
        },
        {
            graphic: <TokenIcon address={CONFIG.addresses.UltraDebtToken} width='28' />,
            padding: '10px 2px 10px 10px',
            title: 'ULTRA circulating',
            stat: <><DisplayNumber value={totalUltraDebt} /></>
        },
        {
            graphic: <TokenIcon address={'all-collats'} width='72' />,
            padding: '10px 2px 10px 10px',
            title: 'Collateral value',
            stat: <>$<DisplayShortNumber value={collateralTvl} /></>
        },
        {
            graphic: <TokenIcon address={CONFIG.addresses.PrismaToken} width='28' />,
            padding: '10px 2px 10px 10px',
            title: 'PRISMA locked',
            stat: <><DisplayShortNumber value={prismaLocked} />&nbsp;(<DisplayNumber value={prismaLockedPct} />%)</>
        }, 
    ]

    return (<Grid container rowSpacing={'30px'} columnSpacing={'32px'}>
        {stats.map((s, key) =>
            <Grid key={key} item lg={3} md={3} sm={12} xs={12}>
                <Typography sx={{...title, marginBottom: '6px' }}>{s.title}</Typography>
                <Card key={key} sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '24px',
                    borderRadius: '12px',
                    border: '1px solid #316EFF',
                    background: 'linear-gradient(67deg, #316EFF -292.36%, rgba(49, 110, 255, 0.00) 92.55%), #242326',
                }}>
                    <CardContent sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        height: '30px',
                    }}>
                        {s.graphic ? <>{s.graphic}&nbsp;</> : <></>}
                        <Typography sx={stat}>
                            {s.stat}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        )}
    </Grid>)
}