import { Box, Grid, Link, Typography } from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { AccordionTextButton } from "./Accordion/AccordionTextButton";
import Accordion from "./Accordion";
import { DOCS_DOMAIN } from "../globals";
import { ReactComponent as FaqIcon } from './../graphics/faq.svg'

const textStyle = {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
}

export default function FAQ() {
    const panels = [
        {
            openTitle: <AccordionTextButton title={'What is the Prisma Protocol?'} open={false} />,
            closeTitle: <AccordionTextButton title={'What is the Prisma Protocol?'} open={true} />,
            content: <Typography sx={textStyle}>
                Prisma is a decentralized borrowing protocol that allows users to mint a stablecoin, mkUSD, that is fully collateralized by liquid staking tokens.
                <br />
                <br />
                In addition to the collateral, the loans are secured by a Stability Pool containing mkUSD and by fellow borrowers collectively acting as guarantors of last resort. Learn more about these mechanisms under liquidations.
                <br />
                <br />
                Prisma as a protocol is non-custodial, immutable, and censorship-resistant. Learn more about the protocol here.
            </Typography>
        },
        {
            openTitle: <AccordionTextButton title={'How do I use the Prisma Protocol?'} open={false} />,
            closeTitle: <AccordionTextButton title={'How do I use the Prisma Protocol?'} open={true} />,
            content: <Typography sx={textStyle}>
                To create a new vault, earn, or vote on governance proposals, head over to the Prisma interface and connect a Web3 wallet. Be aware that each transaction on Ethereum costs Ether (ETH). For a more detailed walkthrough, check out our Help Guides.
                <br />
                <br />
                If you're a developer interested in building on top of the Prisma Protocol, please refer to our extensive docs.
            </Typography>
        },
        {
            openTitle: <AccordionTextButton title={'How does the Prisma Protocol work?'} open={false} />,
            closeTitle: <AccordionTextButton title={'How does the Prisma Protocol work?'} open={true} />,
            content: <Typography sx={textStyle}>
                Prisma is a borrowing protocol. In practical terms, it is a collection of smart contracts that define a standard way to deposit collateral, mint mkUSD, and liquidate positions.
                A vault is where you take out and maintain your loans against a specific collateral type.
                <br />
                <br />
                For a more in-depth description, check out the concepts from the documentation.
                What are the benefits of using Prisma?
                Prisma offers a capital-efficient borrowing experience with:
                <br />
                <br />
                - A collateral ratio of just 120%
                <br />
                - Access to deposit multiple collaterals
                <br />
                - Directly redeemable - mkUSD can be redeemed at face value for the underlying collateral, always and at any time
                <br />
                - Censorship resistant
                <br />
                <br />
                Prisma is also attractive to LST issuers as vePRISMA holders provide access to incentivize certain actions across the Prisma protocol like incentivizing minting of mkUSD with their own LST or redirecting emissions on Prisma.
            </Typography>
        },
        {
            openTitle: <AccordionTextButton title={'What is boost?'} open={false} />,
            closeTitle: <AccordionTextButton title={'What is boost?'} open={true} />,
            content: <Typography sx={textStyle}>
                Vote locking is a feature on Prisma where PRISMA is locked in return for vePRISMA. The longer the time period that PRISMA is locked for, the more vePRISMAs are received. The minimum locking period is 1 week and the maximum period is 52 weeks.
                <br />
                <br />
                One of the major incentives of vote-locking PRISMA is the ability to boost your PRISMA emissions. This means that a vault with "max boost" earns PRISMA rewards at 2x the rate of a vault that is unboosted.
            </Typography>
        },
    ]

    return (<Grid container rowSpacing={'12px'} columnSpacing={'36px'}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '12px' }}>
                <FaqIcon />
            </Box>
            <Typography sx={{
                textAlign: 'center',
                fontFamily: 'Epilogue',
                fontWeight: 500,
                fontSize: '32px',
                lineHeight: '38px',
                marginBottom: '30px',
                marginTop: ['20px', 'unset']
            }}>FAQ</Typography>
            <Accordion panels={panels} />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '30px' }}>
                <HelpOutlineIcon sx={{ color: '#81A6FF', fontWeight: 400, marginBottom: '8px' }} />
                <Typography sx={{
                    fontFamily: 'Epilogue',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    color: '#81A6FF',
                    textAlign: 'center'
                }}>
                    Still unsure?<br />
                    <Link href={DOCS_DOMAIN} target="__blank" rel="noreferrer">Read the docs.</Link>
                </Typography>
            </Box>
        </Grid>
    </Grid>)
}