import React, { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import { LSDSummary, LSDToSystem, Pool, VoteReceiver } from '../types';
import { BASE_API, CCR_RATIO, COLLATERALS, COLLATERAL_COUNT, CONFIG, GAS_COMPENSATION, MAX_MINT_FEE, MAX_REDEMPTION_FEE, MCR_RATIO, MINIMUM_DEBT, MIN_MINT_FEE, MIN_REDEMPTION_FEE, STABLECOIN_SYMBOL } from '../globals';

export interface LandingPageProvider {
    fetch: () => void
    pools: { [key: string]: Pool }
    topPool: Pool
    stabilityPool: Pool
    topPoolApr: number
    keyedLSDSummary: { [key: string]: LSDSummary }
    collateralCount: number
    tmToCollateral: { [key: string]: string }
    etherApr: number
    etherTotalStaked: number
    etherTotalStakedUSD: number
    currentReceiverToWeights: { [key: string]: VoteReceiver }
    troveManagerPrices: { [key: string]: number }
    collateralTvl: number
    totalDebt: number
    totalUltraDebt: number
    prismaLocked: number
    prismaLockedPct: number
}

const LandingPageContext = React.createContext({} as LandingPageProvider)

export function LandingPageProvider({ children }: React.PropsWithChildren<{}>) {
    const [troveManagerPrices, setTroveManagerPrices] = useState<{ [key: string]: number }>({})
    const [pools, setPools] = useState<{ [key: string]: Pool }>({
        [CONFIG.addresses.StabilityPool]: {
            type: 'stability-pool',
            poolName: 'Stability Pool',
            poolAddress: CONFIG.addresses.StabilityPool,
            tokenAddress: CONFIG.addresses.DebtToken,
            symbol: STABLECOIN_SYMBOL,
            tvl: 0,
            tvlUSD: 0,
            boostedPrismaApr: 0,
            unboostedPrismaApr: 0,
            crvApr: 0,
            cvxApr: 0,
            totalUnboostedApr: 0,
            totalBoostedApr: 0,
        }
    })
    const [topPool, setTopPool] = useState<Pool>({
        type: 'stability-pool',
        poolName: 'Stability Pool',
        poolAddress: CONFIG.addresses.StabilityPool,
        tokenAddress: CONFIG.addresses.DebtToken,
        symbol: STABLECOIN_SYMBOL,
        tvl: 0,
        tvlUSD: 0,
        boostedPrismaApr: 0,
        unboostedPrismaApr: 0,
        crvApr: 0,
        cvxApr: 0,
        totalUnboostedApr: 0,
        totalBoostedApr: 0,
    })
    const [stabilityPool, setStabilityPool] = useState<Pool>({
        type: 'stability-pool',
        poolName: 'Stability Pool',
        poolAddress: CONFIG.addresses.StabilityPool,
        tokenAddress: CONFIG.addresses.DebtToken,
        symbol: STABLECOIN_SYMBOL,
        tvl: 0,
        tvlUSD: 0,
        boostedPrismaApr: 0,
        unboostedPrismaApr: 0,
        crvApr: 0,
        cvxApr: 0,
        totalUnboostedApr: 0,
        totalBoostedApr: 0,
    })
    const [topPoolApr, setTopPoolApr] = useState(0)

    const [currentReceiverToWeights, setCurrentReceiverToWeights] = useState<{ [key: string]: VoteReceiver }>({})

    const [keyedLSDSummary, setKeyedLSDSummary] = useState(() => {
        let keyedLSDSummary: { [key: string]: LSDSummary }  = {}
        const collateralsArray: LSDToSystem[] = Object.values(COLLATERALS)
        for (let i = 0; i < collateralsArray.length; i++) {
            keyedLSDSummary[collateralsArray[i].token] = {
                ...collateralsArray[i],
                tvlData: {
                    address: '',
                    coll: 0,
                    collUSD: 0,
                    debt: 0,
                    cr: 250,
                    price: 0
                },
                aprData: {
                    totalBoosted: 0,
                    totalUnboosted: 0,
                    borrowing: {
                        unboosted: 0,
                        boosted: 0,
                    },
                    mint: {
                        unboosted: 0,
                        boosted: 0,
                    },
                    eth: 0,
                },
                maxSystemDebt: 0,
                debtFull: false,
                minimumCollateralRatio: new BigNumber(MCR_RATIO),
                criticalCollateralRatio: new BigNumber(CCR_RATIO),
                minimumNetDebt: new BigNumber(MINIMUM_DEBT),
                gasCompensation: new BigNumber(GAS_COMPENSATION),
                mintFee: new BigNumber(MIN_MINT_FEE),
                minMintFee: new BigNumber(MIN_MINT_FEE),
                maxMintFee: new BigNumber(MAX_MINT_FEE),
                redemptionFee: new BigNumber(MIN_REDEMPTION_FEE),
                maxRedemptionFee: new BigNumber(MAX_REDEMPTION_FEE),
                interestRate: 0,
                inBootstrapPeriod: true,
                bootstrapPeriodEnds: 0,
                numberOfVaults: 0,
                redemptionRebate: -1
            }
        }
        return keyedLSDSummary
    })

    const [collateralCount, setCollateralCount] = useState(COLLATERAL_COUNT)
    const [tmToCollateral, setTmToCollateral] = useState<{ [key: string]: string }>({})

    const [etherApr, setEtherApr] = useState(0)
    const [etherTotalStaked, setEtherTotalStaked] = useState(0)
    const [etherTotalStakedUSD, setEtherTotalStakedUSD] = useState(0)

    const [collateralTvl, setCollateralTvl] = useState(0)
    const [totalDebt, setTotalDebt] = useState(0)
    const [totalUltraDebt, setTotalUltraDebt] = useState(0)
    const [prismaLocked, setPrismaLocked] = useState(0)
    const [prismaLockedPct, setPrismaLockedPct] = useState(0)

    const fetch = useCallback(() => {
        axios.get(`${BASE_API}/v1/landingPage`).then((result: any) => {

            console.log(result.data.data)

            setTroveManagerPrices(result.data.data.troveManagerPrices)
            setPools(result.data.data.pools.pools)
            setStabilityPool(result.data.data.pools.pools[CONFIG.addresses.StabilityPool])
            setTopPoolApr(result.data.data.pools.topPoolAPR)

            setEtherApr(result.data.data.apr)
            setEtherTotalStaked(result.data.data.totalStaked)
            setEtherTotalStakedUSD(result.data.data.totalStakedUSD)

            setCurrentReceiverToWeights(result.data.data.receiverToWeights)

            const tvlData = result.data.data.collateralToTvl
            const aprData = result.data.data.collateralAPRs
            const collateralData = result.data.data.collaterals
            const collateralCount = Number(result.data.data.collateralCount)
            const tmToCollateral = result.data.data.tmToCollateral

            let keyedLSDSummary: { [key: string]: LSDSummary }  = {}

            try {
                const collateralsArray: LSDToSystem[] = Object.values(COLLATERALS)
                for (let i = 0; i < collateralsArray.length; i++) {
                    keyedLSDSummary[collateralsArray[i].trove_manager] = {
                        ...collateralsArray[i],
                        tvlData: tvlData[collateralsArray[i].trove_manager],
                        aprData: aprData[collateralsArray[i].trove_manager],
                        maxSystemDebt: collateralData[collateralsArray[i].trove_manager] ? collateralData[collateralsArray[i].trove_manager].maxSystemDebt : 0,
                        debtFull: collateralData[collateralsArray[i].trove_manager] && tvlData[collateralsArray[i].trove_manager] ?
                            collateralData[collateralsArray[i].trove_manager].maxSystemDebt !== 0 &&
                            tvlData[collateralsArray[i].trove_manager].debt >= collateralData[collateralsArray[i].trove_manager].maxSystemDebt : false,
                        minimumCollateralRatio: new BigNumber(
                            collateralData[collateralsArray[i].trove_manager] ? collateralData[collateralsArray[i].trove_manager].minimumCollateralRatio
                            : MCR_RATIO
                        ),
                        criticalCollateralRatio: new BigNumber(
                            collateralData[collateralsArray[i].trove_manager] ? collateralData[collateralsArray[i].trove_manager].criticalCollateralRatio
                            : CCR_RATIO
                        ),
                        minimumNetDebt: new BigNumber(
                            collateralData[collateralsArray[i].trove_manager] ? collateralData[collateralsArray[i].trove_manager].minimumNetDebt
                            : MINIMUM_DEBT
                        ),
                        gasCompensation: new BigNumber(
                            collateralData[collateralsArray[i].trove_manager] ? collateralData[collateralsArray[i].trove_manager].gasCompensation
                            : GAS_COMPENSATION
                        ),
                        mintFee: new BigNumber(
                            collateralData[collateralsArray[i].trove_manager] ? collateralData[collateralsArray[i].trove_manager].borrowingRate
                            : MIN_MINT_FEE
                        ),
                        minMintFee: new BigNumber(collateralData[collateralsArray[i].trove_manager] ?
                            collateralData[collateralsArray[i].trove_manager].borrowingFeeFloor
                            : MIN_MINT_FEE
                        ),
                        maxMintFee: new BigNumber(collateralData[collateralsArray[i].trove_manager] ?
                            collateralData[collateralsArray[i].trove_manager].maxBorrowingFee
                            : MAX_MINT_FEE
                        ),
                        redemptionFee: new BigNumber(collateralData[collateralsArray[i].trove_manager] ?
                            collateralData[collateralsArray[i].trove_manager].redemptionRate
                            : MIN_REDEMPTION_FEE
                        ),
                        maxRedemptionFee: new BigNumber(collateralData[collateralsArray[i].trove_manager] ?
                            collateralData[collateralsArray[i].trove_manager].maxRedemptionRate
                            : MAX_REDEMPTION_FEE
                        ),
                        interestRate: collateralData[collateralsArray[i].trove_manager] ? collateralData[collateralsArray[i].trove_manager].interestRate : 0,
                        inBootstrapPeriod: collateralData[collateralsArray[i].trove_manager] ? collateralData[collateralsArray[i].trove_manager].inBootstrapPeriod : true,
                        bootstrapPeriodEnds: collateralData[collateralsArray[i].trove_manager] ? collateralData[collateralsArray[i].trove_manager].bootstrapPeriodEnds : 0,
                        numberOfVaults: collateralData[collateralsArray[i].trove_manager] ? collateralData[collateralsArray[i].trove_manager].numberOfVaults : 0,
                        type: collateralsArray[i].type,
                        redemptionRebate: collateralData[collateralsArray[i].trove_manager] ? collateralData[collateralsArray[i].trove_manager].redemptionRebate : -1,
                    }
                }
            } catch (e) {
                console.error(e)
            }

            setKeyedLSDSummary(keyedLSDSummary)
            setCollateralCount(collateralCount)
            setTmToCollateral(tmToCollateral)
            setCollateralTvl(result.data.data.collateralTvl)
            setTotalDebt(result.data.data.totalDebt)
            setTotalUltraDebt(result.data.data.totalUltraDebt)
            setPrismaLocked(result.data.data.prismaLocked)
            setPrismaLockedPct(result.data.data.prismaLockedPct)
        }).catch((e) => console.error(e))
    }, [])
    
    useEffect(() => {
        fetch()

        const interval = setInterval(() => fetch(), 30000)
        return () => clearInterval(interval)
    }, [])

    return (<LandingPageContext.Provider
            value={{
                fetch,
                pools,
                stabilityPool,
                topPool,
                topPoolApr,
                keyedLSDSummary,
                collateralCount,
                tmToCollateral,
                etherApr,
                etherTotalStaked,
                etherTotalStakedUSD,
                currentReceiverToWeights,
                troveManagerPrices,
                collateralTvl,
                totalDebt,
                totalUltraDebt,
                prismaLocked,
                prismaLockedPct,
            }}
        >
            {children}
        </LandingPageContext.Provider>
    )
}

export const useLandingPageContext = () => useContext(LandingPageContext)
