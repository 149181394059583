import { Box, Typography } from '@mui/material';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

type AccordionButtonProps = {
    title: string
    open: boolean
}

export function AccordionTextButton({ title, open }: AccordionButtonProps) {
    return (<Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ 
            fontFamily: 'Epilogue',
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: '28px',
            color: '#FFFFFF',
            display: 'flex',
            flexDirection: 'row',
        }}>
            {title}
        </Typography>
        <Box>
            {open ? <KeyboardArrowUpOutlinedIcon sx={{ color: '#FFFFFF', fontSize: '24px' }} /> : <KeyboardArrowDownOutlinedIcon sx={{ color: '#FFFFFF', fontSize: '24px' }} />}
        </Box>
    </Box>)
}