import { Box, Typography } from "@mui/material";

export default function MainHeading() {
    return (<Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    }}>
        <Typography sx={{
            fontSize: '72px',
            fontFamily: 'Epilogue',
            fontWeight: 400,
            lineHeight: '90px',
            color: '#FFFFFF',
            marginBottom: '20px',
            display: ['none', 'block' ]
        }}>
            The <b>end game</b> for<br /> Liquid (re)Staking Tokens
        </Typography>
        <Typography sx={{
            fontSize: '36px',
            fontFamily: 'Epilogue',
            fontWeight: 400,
            lineHeight: '45px',
            color: '#FFFFFF',
            marginBottom: '30px',
            display: ['block', 'none' ],
            textAlign: 'center'
        }}>
            The <b>end game</b> for Liquid (re)Staking Tokens
        </Typography>
        <Typography sx={{
            fontSize: '24px',
            fontWeight: 400,
            lineHeight: '35px',
            color: '#FFFFFF',
            marginBottom: '20px',
            display: ['none', 'block' ]
        }}>
            Non-custodial and decentralized Ethereum <span style={{ fontWeight: 700 }}>LST & LRT backed stablecoins</span>
        </Typography>
        <Typography sx={{
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: '26px',
            color: '#FFFFFF',
            marginBottom: '24px',
            display: ['block', 'none' ],
            textAlign: 'center'
        }}>
            Non-custodial and decentralized Ethereum <span style={{ fontWeight: 700 }}>LST & LRT backed stablecoins</span>
        </Typography>
    </Box>)
}