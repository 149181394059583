import { ReactComponent as Discord } from './../discord-mark-white.svg'

export default function DiscordIcon() {
    return (<>
        <svg width={0} height={0}>
            <linearGradient id="linearColors1" x1={0} y1={0} x2={1} y2={0}>
                <stop offset={0} stopColor="#794DEC" />
                <stop offset={1} stopColor="#316EFF" />
            </linearGradient>
        </svg>
        <Discord
            height='25px'
            width='25px'
            style={{ fill: "url(#linearColors1)" }}
        />
    </>)
}