import { Box, Grid, Typography } from "@mui/material";
import { LSDSummary } from "../types";
import CollateralOverview from "./CollateralOverview";
import { useLandingPageContext } from "../api/useLandingPageContext";

export default function CollateralSelection() {
    const { keyedLSDSummary, currentReceiverToWeights } = useLandingPageContext()

    return (<Grid container columnSpacing={'32px'} rowSpacing={'50px'} sx={{ justifyContent: 'flex-start' }}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container columnSpacing={'32px'} rowSpacing={'32px'} sx={{ justifyContent: 'flex-start' }}>
                <Grid item lg={5} md={5} sm={12} xs={12}>
                    <Typography sx={{
                        fontFamily: 'Epilogue',
                        fontSize: '32px',
                        fontWeight: 700,
                        lineHeight: '38px',
                        textAlign: ['center', 'left'],
                    }}>
                        Choose a collateral<br /> to borrow against
                    </Typography>
                </Grid>
                <Grid item lg={7} md={7} sm={12} xs={12}>
                    <Typography sx={{
                        fontSize: '20px',
                        fontWeight: 300,
                        lineHeight: '30px',
                        textAlign: ['center', 'left'],
                    }}>
                        <span style={{ fontWeight: 700 }}>Prisma</span> supports multiple collateral types, therefore users can open multiple vaults.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
        {Object.values(keyedLSDSummary).map((lsd: LSDSummary, index: number) =>
            <Grid item xs={12} sm={12} md={3} lg={3} key={index}>
                <CollateralOverview lsd={lsd} weights={currentReceiverToWeights[lsd.trove_manager] ? currentReceiverToWeights[lsd.trove_manager].weights : []} />
            </Grid>
        )}
    </Grid>)
}
