import { Box, Typography, useTheme } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import numeral from 'numeral';
import { LSDSummary, ReceiverWeight } from '../../../types';
import { STABLECOIN_SYMBOL } from '../../../globals';
import { DisplayShortNumber } from '../../DisplayShortNumber';
import PieChart from '../../../components/PieChart';
import { ReactComponent as PrismaSolid } from '../../../graphics/prisma-solid.svg';

const weightTypeToReadable = (type: 'unknown' | 'debt' | 'mint' | 'pool') => {
    switch (type) {
        case 'unknown':
            return 'Default'
        case 'debt':
            return 'Active Debt'
        case 'mint':
            return `Mint ${STABLECOIN_SYMBOL}`
        case 'pool':
            return `Deposit ${STABLECOIN_SYMBOL}`
        default:
            return 'Default'
    }
}

type Props = {
    lsd: LSDSummary
    weights: ReceiverWeight[]
}

function AprDisplay({ lsd, weights }: Props) {
    const theme = useTheme()

    const textStyle = {
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '18px',
        color: theme.palette.primaryText.main
    }

    return (<Box sx={{
        background: 'rgba(49, 110, 255, 0.15)',
        marginX: '12px',
        marginY: '12px',
        borderRadius: '18px',
    }}>
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingY: '6px',
            paddingLeft: '10px',
            paddingRight: '6px',
            background: 'rgba(49, 110, 255, 0.2)',
            borderRadius: '18px'
        }}>
            <Typography sx={textStyle}>APR</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                <Typography sx={{...textStyle, display: 'flex', flexDirection: 'row', alignItems: 'center' }} marginRight={'6px'}>
                    <DisplayShortNumber value={lsd.aprData.totalUnboosted} />%&nbsp;
                    <ArrowRightAltIcon fontSize="inherit" />&nbsp;
                    <DisplayShortNumber value={lsd.aprData.totalBoosted} />%
                </Typography>
                <PieChart
                    size={22}
                    data={weights.map((voteOption, key) => {
                        return {
                            name:
                                <Typography sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                    {weightTypeToReadable(voteOption.type)}:&nbsp;
                                    <PrismaSolid height='14' width='14' />&nbsp;
                                    {numeral(voteOption.emissions).format('0.00a')}
                                </Typography>,
                            value: voteOption.emissions,
                            fill: `rgba(49, 110, 255, ${key === 0 ? 0.5 : 1})`,
                        }}
                    )}
                />
            </Box>
        </Box>
    </Box>)
}

export default AprDisplay;
