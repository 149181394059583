import { Button } from "@mui/material";
import { APP_URL } from "../globals";

export default function OpenApp() {
    return (<Button
        href={APP_URL}
        target="__blank"
		rel="noreferrer"
        variant='blue'
        sx={{
            height: 'fit-content',
            width: '180px',
            fontSize: '16px !important',
            fontFamily: "'Karla',sans-serif !important"
        }}
    >
        Open App
    </Button>)
}