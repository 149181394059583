import * as React from 'react';
import { AppBar, Box, Divider, Drawer, IconButton, Link, Toolbar } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import MenuItems from './MenuItems';
import TwitterIcon from './TwitterIcon';
import DiscordIcon from './DiscordIcon';
import TelegramIcon from './TelegramIcon';
import OpenApp from './OpenApp';
import { DISCORD, TELEGRAM, TWITTER } from '../globals';

const drawerWidth = 240;

export default function MainAppBar() {
	const [mobileOpen, setMobileOpen] = React.useState(false)

	const handleDrawerToggle = () => {
		setMobileOpen((prevState) => !prevState)
	}

	const drawer = (
		<Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
			<Divider />
			<MenuItems open={true} />
			<Box sx={{ marginTop: '30%'}}>
				<OpenApp />
			</Box>
		</Box>
	)

	return (<>
		<AppBar component="nav">
			<Toolbar sx={{
				margin: 'auto',
				width: ['100%', '1200px'],
				display: { xs: 'inherit', sm: 'flex' },
				justifyContent: { sm: 'space-between' }
			}}>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					edge="start"
					onClick={handleDrawerToggle}
					sx={{ ml: [2, 0], mr: 2, display: { sm: 'none' } }}
				>
					<MenuIcon />
				</IconButton>
				<Box sx={{ display: { xs: 'flex', sm: 'flex' }, paddingRight: ['20px', '60px'] }}>
					<Link href="#home" sx={{ display: { xs: 'flex', sm: 'flex' }}}>
						<img height='50' src='/prisma-logo-light.png' />
					</Link>
				</Box>
				<Box sx={{
					display: { xs: 'none', sm: 'flex' },
					background: '#000',
					borderRadius: '12px',
					paddingY: '6px',
					paddingLeft: '22px',
					paddingRight: '12px',
					flexDirection: 'row',
					alignItems: 'center'
				}}>
					<MenuItems open={true} />
					<Box sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItem: 'center',
						justifyContent: 'space-between',
						paddingX: '24px'
					}}>
						<Link sx={{
								'&:hover': { opacity: 0.3 },
								marginX: '9px'
							}}
							href={DISCORD}
							target="__blank"
							rel="noreferrer">
							<DiscordIcon />
						</Link>
						<Link sx={{
								'&:hover': { opacity: 0.3 },
								'& > svg': { fontSize: '25px' },
								marginX: '9px'
							}}
							href={TELEGRAM}
							target="__blank"
							rel="noreferrer">
								<TelegramIcon />
						</Link>
						<Link sx={{
								'&:hover': { opacity: 0.3 },
								'& > svg': { fontSize: '25px' },
								marginX: '9px'
							}}
							href={TWITTER}
							target="__blank"
							rel="noreferrer">
								<TwitterIcon />
						</Link>
					</Box>
					<OpenApp />
				</Box>
			</Toolbar>
		</AppBar>
		<Box component="nav" sx={{ background: '#000' }}>
			<Drawer
				variant="temporary"
				open={mobileOpen}
				onClose={handleDrawerToggle}
				ModalProps={{ keepMounted: true }}
				sx={{
					display: { xs: 'block', sm: 'none' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: '#000' },
				}}
			>
				{drawer}
			</Drawer>
		</Box>
	</>)
}