import { useTheme } from "@mui/material";
import { useState } from "react";

type Props = {
    address: string;
    height?: string;
    width?: string;
}

export default function TokenIcon({ address, height = '32', width = '32' }: Props) {
  const theme = useTheme()
  const [didLoad, setLoad] = useState<boolean | undefined>(undefined);

  const onLoad = () => {
    setLoad(true);

  }

  const onError = () => {
    setLoad(false);
  }

  return <>{didLoad === undefined ? <img
    src={`/tokens/${address}/icon.svg`}
    alt={`${address}-icon`}
    onLoad={onLoad}
    onError={onError}
    height={height}
    width={width}
  /> : didLoad ? <img
    src={`/tokens/${address}/icon.svg`}
    alt={`${address}-icon`}
    height={height}
    width={width}
  /> :
    <span style={{
      height: `${height}px`,
      width: `${width}px`,
      minWidth: `${width}px`,
      background: theme.palette.noIcon.main,
      borderRadius: '50%'
    }} />}
  </>
}