import MuiTwitterIcon from '@mui/icons-material/Twitter'

export default function TwitterIcon() {
    return (<>
        <svg width={0} height={0}>
            <linearGradient id="linearColors" x1={0} y1={0} x2={1} y2={0}>
                <stop offset={0} stopColor="#794DEC" />
                <stop offset={1} stopColor="#316EFF" />
            </linearGradient>
        </svg>
        <MuiTwitterIcon
            height='inherit'
            width='inherit'
            sx={{
                fontSize: 'inherit',
                fill: "url(#linearColors)"
            }}
        />
    </>)
}