import { Backdrop, Box, Button, Fade, IconButton, Modal, Typography, useTheme, Link as MuiLink } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { LSDSummary } from '../../types';
import { APP_URL, DOCS_DOMAIN, STABLECOIN_SYMBOL } from '../../globals';
import { DisplayNumber } from '../DisplayNumber';
import TokenIcon from '../TokenIcon';
import InfoTooltip from '../InfoTooltip';

type AprModalProps = {
    lsd: LSDSummary
    open: boolean
    setOpen: (open: boolean) => void
}

export default function APRModal({ lsd, setOpen, open }: AprModalProps) {
    const theme = useTheme()
    
    const handleClose = () => {
        setOpen(false)
    }

    const textStyling = {
        color: theme.palette.text.primary,
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    }

    const statStyling = {
        color: theme.palette.text.primary,
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '22px'
    }

    const stats = [{
            title: 'Active debt',
            value: <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <DisplayNumber value={lsd.aprData.borrowing.unboosted} />%&nbsp;
                <ArrowRightAltIcon fontSize="inherit" />
                &nbsp;<DisplayNumber value={lsd.aprData.borrowing.boosted} />%
            </span>,
            info: <>
                PRISMA is distributed for maintaining active debt.&nbsp;
                <MuiLink href={`${DOCS_DOMAIN}/governance/the-prisma-token`} target="__blank" rel="noreferrer">Read more.</MuiLink>
            </>
        },
        {
            title: 'Mint',
            value: <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <DisplayNumber value={lsd.aprData.mint.unboosted} />%&nbsp;
                <ArrowRightAltIcon fontSize="inherit" />
                &nbsp;<DisplayNumber value={lsd.aprData.mint.boosted} />%
            </span>,
            info: `Minting ${STABLECOIN_SYMBOL} against this collateral type rewards PRISMA tokens. The amount is based on how many votes it received in the previous week and how many other people mint against this collateral in a 24 hour period.`
        },
        {
            title: 'Staked Ether APR',
            value: <><DisplayNumber value={lsd.aprData.eth} />%</>,
            info: "LST's represent a staked position - in this case within the Proof-of-Stake network Ethereum. Staked ETH earns rewards, and is paid out in ETH. Therefore, simply by holding an LST you will earn ETH."
        },
        
    ]

    if (lsd.interestRate > 0.01) {
        stats.push({
            title: 'Debt Interest Rate',
            value: <><DisplayNumber value={lsd.interestRate > 0.01 ? lsd.interestRate : 0} precision={2} />%</>,
            info: `The fee that accrues over time on outstanding debt. It is charged in ${STABLECOIN_SYMBOL}.`
        })
    }

    return (<Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
    >
        <Fade in={open}>
            <Box sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '8px',
                width: '600px',
                background: theme.palette.cardBg.main,
                border: 'unset',
            }}>
                <Box sx={{
                    justifyContent: 'center',
                    py: '14px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    position: 'relative',
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    background: theme.palette.cardHeaderBg.main,
                    borderBottom: '0.5px solid transparent',
                    borderImage: theme.palette.rainbow.main,
                    borderImageSlice: 0.5 
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Typography sx={{
                            fontSize: '16px',
                            fontWeight: 600,
                            fontFamily: 'Epilogue',
                            textAlign: 'center',
                            lineHeight: '19px',
                            color: theme.palette.text.secondary
                        }}>
                            APR Breakdown
                        </Typography>
                    </Box>
                    <Box sx={{ position: 'absolute', right: 0, top: 0, marginTop: '4px' }}>
                        <IconButton onClick={handleClose} aria-label="close" sx={{ color: theme.palette.text.secondary }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingY: '16px' }}>
                    <Box sx={{ paddingBottom: '16px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '8px' }}>
                            <TokenIcon width='92' height='92' address={lsd.token} />
                        </Box>
                        <Typography sx={{ 
                            fontFamily: 'Epilogue',
                            fontWeight: 700,
                            fontSize: '32px',
                            lineHeight: '44px',
                            textAlign: 'center',
                            color: theme.palette.text.primary,
                            paddingBottom: '8px'
                        }}>
                            {lsd.symbol}
                        </Typography>
                        <Typography sx={{
                            fontWeight: 700,
                            fontSize: '32px',
                            lineHeight: '44px',
                            textAlign: 'center',
                            color: theme.palette.primaryText.main,
                        }}>
                            <DisplayNumber value={lsd.aprData.totalBoosted} />%
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        background: theme.palette.cardTertiaryBg.main,
                        borderTop: '1px solid rgba(0, 34, 55, 0.1)',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            paddingTop: '9px',
                            paddingBottom: '2px',
                            paddingX: '24px',
                        }}>
                            <Typography sx={{
                                color: theme.palette.text.primary,
                                fontSize: '20px',
                                fontWeight: 700,
                                lineHeight: '28px'
                            }}>Breakdown</Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            paddingX: '24px',
                        }}>
                            <Typography sx={{
                                color: theme.palette.text.primary,
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '24px',
                                opacity: 0.5,
                                mr: '44px'
                            }}>1x</Typography>
                            <Typography sx={{
                                color: theme.palette.text.primary,
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '24px',
                                opacity: 0.5
                            }}>2x</Typography>
                        </Box>
                        {stats.map((stat, key) =>
                            <Box
                                key={key}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    borderBottom: key === stats.length-1 ? 'unset' : '1px solid rgba(0, 34, 55, 0.1)',
                                    paddingX: '24px',
                                    paddingTop: key === 0 ? '4px' : '14px',
                                    paddingBottom: '14px'
                                }}
                            >
                                <Typography sx={textStyling}>
                                    {stat.title}&nbsp;
                                    <InfoTooltip info={stat.info} />
                                </Typography>
                                <Typography sx={statStyling}>{stat.value}</Typography>
                            </Box>
                        )}
                    </Box>
                    <Box sx={{ paddingX: '24px', paddingTop: '12px' }}>
                        <Typography sx={{ fontSize: '14px', lineHeight: 'normal', fontWeight: 400 }}>
                            <MuiLink href={`${APP_URL}/lock`} target="__blank" rel="noreferrer">Lock PRISMA</MuiLink>
                            &nbsp;to claim rewards at the boosted (2x) rate. <MuiLink href={`${DOCS_DOMAIN}/governance/emissions-boosting`} target="__blank" rel="noreferrer">Find out more.</MuiLink>
                        </Typography>
                    </Box>
                    <Box sx={{ paddingTop: '24px', margin: 'auto' }}>
                        <Button variant='outlined' sx={{ width: '200px' }} onClick={handleClose}>Close</Button>
                    </Box>
                </Box>
            </Box>
        </Fade>
    </Modal>)
}