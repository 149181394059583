import { createContext, useMemo } from "react";
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		white: true;
		blue: true;
		connect: true;
		sort: true;
		transparent: true;
		collateral: true
		max: true
		showaction: true
		back: true
		discord: true
	}
}

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		body3: true;
		balance: true;
	}
}

declare module '@mui/material/styles' {
	interface Palette {
		white: Palette['primary']
		black: Palette['primary']
		primaryText: Palette['primary']
		tertiaryText: Palette['primary']
		quaternaryText: Palette['primary']
		quinaryText: Palette['primary']
		senaryText: Palette['primary']
		menuText: Palette['primary']
		inputText: Palette['primary']
		inputBg: Palette['primary']
		inputBoxShadow: Palette['primary']
		cardBoxShadow: Palette['primary']
		appBg: Palette['primary']
		appBarBg: Palette['primary']
		buttonBG: Palette['primary']
		cardHeaderBg: Palette['primary']
		cardBg: Palette['primary']
		cardSecondaryBg: Palette['primary']
		cardTertiaryBg: Palette['primary']
		cardBorder: Palette['primary']
		rainbow: Palette['primary']
		rainbowBorder: Palette['primary']
		subTabBg: Palette['primary']
		showActionBtn: Palette['primary']
		showActionBtnText: Palette['primary']
		noIcon: Palette['primary']
		modalBackdrop: Palette['primary']
		collatRatio: Palette['primary']
		addressBg: Palette['primary']
	}

	// allow configuration using `createTheme`
	interface PaletteOptions {
		white?: PaletteOptions['primary']
		black?: PaletteOptions['primary']
		primaryText?: PaletteOptions['primary']
		tertiaryText?: PaletteOptions['primary']
		quaternaryText?: PaletteOptions['primary']
		quinaryText?: PaletteOptions['primary']
		senaryText?: PaletteOptions['primary']
		menuText?: PaletteOptions['primary']
		inputText?: PaletteOptions['primary']
		inputBg?: PaletteOptions['primary']
		inputBoxShadow?: PaletteOptions['primary']
		cardBoxShadow?: PaletteOptions['primary']
		appBg?: PaletteOptions['primary']
		appBarBg?: PaletteOptions['primary']
		buttonBG?: PaletteOptions['primary']
		cardHeaderBg?: PaletteOptions['primary']
		cardBg?: PaletteOptions['primary']
		cardSecondaryBg?: PaletteOptions['primary']
		cardTertiaryBg?: PaletteOptions['primary']
		cardBorder?: PaletteOptions['primary']
		rainbow?: PaletteOptions['primary']
		rainbowBorder?: PaletteOptions['primary']
		subTabBg?: PaletteOptions['primary']
		showActionBtn?: PaletteOptions['primary']
		showActionBtnText?: PaletteOptions['primary']
		noIcon?: PaletteOptions['primary']
		modalBackdrop?: PaletteOptions['primary']
		collatRatio?: PaletteOptions['primary']
		addressBg?: PaletteOptions['primary']
	}
}

export const ColorModeContext = createContext({ toggleColorMode: () => { } });

const createAppTheme = () => {
	const colorsAndTypography = createTheme({
		palette: {
			...({
				// palette values for dark mode
				white: {
					main: '#FFFFFF'
				},
				black: {
					main: '#000000'
				},
				primary: {
					main: '#316EFF'
				},
				secondary: {
					main: '#242326'
				},
				background: {
					default: "#F5F5F5",
					paper: "#F5F5F5",
				},
				primaryBG: {
					main: '#F5F5F5'
				},
				text: {
					primary: "#FFFFFF",
					secondary: "#FFFFFF",
				},
				primaryText: {
					main: '#316EFF'
				},
				tertiaryText: {
					main: '#FFFFFF'
				},
				quaternaryText: {
					main: '#75848D'
				},
				quinaryText: {
					main: '#242326'
				},
				senaryText: {
					main: '#768188'
				},
				menuText: {
					main: '#FFFFFF'
				},
				inputBg: {
					main: '#242326'
				},
				inputText: {
					main: '#CAD1DD'
				},
				inputBoxShadow: {
					main: '#316EFF'
				},
				appBg: {
					main: '#0D0D0E'
				},
				appBarBg: {
					main: '#242326'
				},
				buttonBG: {
					main: '#FFFFFF'
				},
				cardHeaderBg: {
					main: '#242326'
				},
				cardBg: {
					main: '#25252A'
				},
				cardSecondaryBg: {
					main: '#242326'
				},
				cardTertiaryBg: {
					main: '#242326'
				},
				cardBoxShadow: {
					main: 'rgba(0, 0, 0, 0.1)'
				},
				cardBorder: {
					main: '#EBEBEB'
				},
				rainbow: {
					main: 'linear-gradient(90deg, #FF0000 0%, #EBFF00 32.81%, #00F0FF 70.31%, #FA00FF 100%)'
				},
				rainbowBorder: {
					main: 'linear-gradient(white, white), linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(235, 255, 0, 1) 32.81%, rgba(0, 240, 255, 1) 70.31%, rgba(250, 0, 255, 1) 100%)'
				},
				subTabBg: {
					main: 'linear-gradient(0deg, #242326, #242326), #FFFFFF'
				},
				showActionBtn: {
					main: '#242326'
				},
				showActionBtnText: {
					main: '#FFFFFF'
				},
				noIcon: {
					main: '#F1F1F1'
				},
				modalBackdrop: {
					main: 'rgba(103, 97, 107, 0.1)'
				},
				collatRatio: {
					main: 'linear-gradient(90deg, #FF747A 0% 20%, #FFBB16 20% 35%, #F7E06B 35% 50%, #18E180 50%)'
				},
				addressBg: {
					main: 'rgba(202, 233, 255, 0.4)'
				}
			}),
		},
		typography: (palette: any) => ({
			fontFamily: `'Karla', sans-serif`,
			color: palette.text.primary,
			h1: {
				fontFamily: `'Epilogue', sans-serif`,
				fontWeight: 600,
			},
			h2: {
				fontSize: '36px',
				fontFamily: `'Epilogue', sans-serif`,
				lineHeight: '50px',
				fontWeight: 600,
			},
			h3: {
				fontSize: '24px',
				fontFamily: `'Epilogue', sans-serif`,
				lineHeight: '28px',
				fontWeight: 600,
			},
			h4: {
				fontSize: '14px',
				fontWeight: 600,
			},
			h5: {
				fontSize: '16px',
				fontFamily: `'Epilogue', sans-serif`,
				fontStyle: 'normal',
				fontWeight: '600',
				lineHeight: '19px',
			},
			h6: {
				fontWeight: 'normal',
				lineHeight: '20px',
			},
			body1: {
				fontWeight: 400,
				fontSize: '16px',
				lineHeight: '20px',
			},
			body2: {
				fontWeight: 'bold',
				fontSize: '20px',
				lineHeight: '25px',
			},
			subtitle1: {
				fontSize: '16px',
				lineHeight: '20px',
				fontWeight: 400,
			},
			subtitle2: {
				fontSize: '14px',
				fontWeight: 600,
				display: 'flex',
				alignItems: 'center'
			}
		}),
	})

	return createTheme({
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					body: {
						backgroundColor: colorsAndTypography.palette.appBg.main
					},
					'.collateral-card-header': {
						paddingTop: '24px',
						paddingBottom: '20px',
						position: 'relative',
						height: 'fit-content',
						background: colorsAndTypography.palette.cardTertiaryBg.main,
						borderBottom: '0.5px solid transparent',
						borderImage: colorsAndTypography.palette.rainbow.main,
						borderImageSlice: '0.5px',
						borderTopLeftRadius: '12px',
						borderTopRightRadius: '12px',
					},
					'.user-account-overview-card-header': {
						paddingTop: '18px',
						paddingBottom: '18px',
						position: 'relative',
						height: 'fit-content',
						background: colorsAndTypography.palette.cardTertiaryBg.main,
						'&:before,&:after': {
							content: '" "',
							position: 'absolute',
							width: '100%',
							height: '50%',
							zIndex: '1',
						},
						'&:before': {
							top: '0px',
							left: '0px',
							background: colorsAndTypography.palette.secondary.main,
							borderBottom: '1px solid transparent',
							borderImage: colorsAndTypography.palette.rainbow.main,
							borderImageSlice: 1
						},
						'&:after': {
							bottom: '0px',
							right: '0px',
							background: colorsAndTypography.palette.cardBg.main
						}
					},
					'.modal-header': {
						paddingTop: '12px',
						paddingBottom: '0px',
						position: 'relative',
						height: 'fit-content',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						'&:before,&:after': {
							borderTopLeftRadius: '8px',
							borderTopRightRadius: '8px',
							content: '" "',
							position: 'absolute',
							width: '100%',
							height: '50%',
							zIndex: '1',
						},
						'&:before': {
							top: '0px',
							left: '0px',
							background: colorsAndTypography.palette.secondary.main,
							borderBottom: '1px solid transparent',
							borderImage: colorsAndTypography.palette.rainbow.main,
							borderImageSlice: 1
						},
						'&:after': {
							bottom: '0px',
							right: '0px',
							background: colorsAndTypography.palette.cardBg.main
						}
					},
					'.main-tabs': {
						width: '100%',
						minHeight: 'unset !important',
						borderRadius: '46px',
						'& > .MuiTabs-scroller': {
							background: colorsAndTypography.palette.primary.main,
							borderRadius: '46px',
							marginLeft: '32px',
							marginRight: '32px'
						},
						'& > * > .MuiTabs-flexContainer': {
							justifyContent: 'center',
							flexDirection: ['column', 'row'],
							border: 'unset',
							margin: '2px !important'
						},
						'& > * > .MuiTabs-scroller > span': {
						},
						'& > * > * > button': {
							borderRadius: '46px',
							textTransform: 'none',
							minHeight: 'unset',
							maxWidth: ['unset', 'inherit'],
						},
						'& > * > * > button.MuiTab-root': {
							color: colorsAndTypography.palette.text.secondary,
							fontSize: '14px',
							fontWeight: 500,
							lineHeight: '20px',
							opacity: 1,
							'&.Mui-selected': {
								color: colorsAndTypography.palette.quinaryText.main,
								background: colorsAndTypography.palette.buttonBG.main,
								opacity: 1,
							},
							'& > span': {
								color: colorsAndTypography.palette.text.secondary,
							},
						}
					},
					'.sub-tabs': {
						margin: 'auto',
						width: 'fit-content',
						minHeight: 'unset !important',
						borderRadius: '46px',
						background: colorsAndTypography.palette.buttonBG.main,
						'& > * > .MuiTabs-flexContainer': {
							justifyContent: 'center',
							flexDirection: ['column', 'row'],
							border: 'unset',
							margin: '2px !important'
						},
						'& > * > .MuiTabs-scroller > span': {
						},
						'& > * > * > button': {
							borderRadius: '46px',
							textTransform: 'none',
							minHeight: 'unset',
							maxWidth: ['unset', 'inherit'],
						},
						'& > * > * > button.MuiTab-root': {
							color: colorsAndTypography.palette.secondary.main,
							fontSize: '14px',
							fontWeight: 500,
							lineHeight: '20px',
							opacity: 1,
							'&.Mui-selected': {
								color: colorsAndTypography.palette.text.secondary,
								background: colorsAndTypography.palette.subTabBg.main,
								opacity: 1,
							},
							'& > span': {
								color: colorsAndTypography.palette.text.secondary,
							},
						}
					},
					'.vote-tabs': {
						paddingLeft: '24px',
						paddingRight: '24px',
						width: 'fit-content',
						minHeight: 'unset !important',
						borderRadius: '46px',
						background: colorsAndTypography.palette.buttonBG.main,
						'& > * > .MuiTabs-flexContainer': {
							justifyContent: 'center',
							flexDirection: ['column', 'row'],
							border: 'unset',
							margin: '2px !important'
						},
						'& > * > .MuiTabs-scroller > span': {
						},
						'& > * > * > button': {
							borderRadius: '46px',
							textTransform: 'none',
							minHeight: 'unset',
							maxWidth: ['unset', 'inherit'],
						},
						'& > * > * > button.MuiTab-root': {
							color: colorsAndTypography.palette.secondary.main,
							fontSize: '14px',
							fontWeight: 500,
							lineHeight: '20px',
							opacity: 1,
							'&.Mui-selected': {
								color: colorsAndTypography.palette.text.secondary,
								background: colorsAndTypography.palette.subTabBg.main,
								opacity: 1,
							},
							'& > span': {
								color: colorsAndTypography.palette.text.secondary,
							},
						}
					}
				}
			},
			MuiAppBar: {
				styleOverrides: {
					root: {
						'& .MuiListItemText-root > span': {
							fontSize: '16px',
							color: colorsAndTypography.palette.menuText.main,
							fontWeight: 500,
							lineHeight: '22px',
							fontFamily: `Epilogue`,
						},
						'& .MuiListItemButton-root': {
							fontSize: '20px',
							color: colorsAndTypography.palette.menuText.main,
							fontWeight: 500,
							lineHeight: '24px',
							'&:hover': {
								fontFamily: 'Epilogue !important',
								fontWeight: '700 !important',
								background: 'unset'
							}
						},
						'& .MuiButton-outlined': {
							fontSize: '20px',
							fontFamily: `Epilogue`,
						},
						'& .MuiButton-text': {
							fontSize: '20px',
							fontFamily: `Epilogue`,
						},
						'& a': {
							fontSize: '20px',
							fontFamily: `Epilogue`,
							border: 'unset',
							textDecoration: 'none'
						},
						fontFamily: `Epilogue`,
						color: colorsAndTypography.palette.menuText.main,
						background: 'unset',
						boxShadow: 'unset',
						padding: 'unset',
						paddingTop: '12px',
						paddingBottom: '12px'
					},
				},
			},
			MuiAccordion: {
				styleOverrides: {
					root: {
						backgroundColor: 'unset',
					}
				}
			},
			MuiDrawer: {
				styleOverrides: {
					root: {
						color: colorsAndTypography.palette.text.secondary,
						border: 'unset',
					},
					paper: {
						border: 'unset',
						color: colorsAndTypography.palette.text.secondary,
					}
				}
			},
			MuiToolbar: {
				styleOverrides: {
					root: {
						justifyContent: 'space-between',
						padding: 'unset !important',
						//paddingRight: '24px !important'
					},
				}
			},
			MuiSvgIcon: {
				styleOverrides: {
					root: {
					}
				}
			},
			MuiContainer: {
				styleOverrides: {
					root: {
						marginTop: '100px !important'
					}
				}
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						borderRadius: '0px'
					},
				},
			},
			MuiCard: {
				styleOverrides: {
					root: {
						padding: 'unset',
						background: colorsAndTypography.palette.cardBg.main,
						boxShadow: `0px 0px 4px ${colorsAndTypography.palette.cardBoxShadow.main}`,
						borderRadius: '12px'
					},
				},
			},
			MuiCardHeader: {
				styleOverrides: {
					root: {
						paddingTop: '16px',
						paddingBottom: '12px',
						color: colorsAndTypography.palette.text.secondary,
						background: colorsAndTypography.palette.secondary.main,
						boxShadow: `0px 0px 4px ${colorsAndTypography.palette.cardBoxShadow.main}`,
						borderBottom: '1px solid transparent',
						borderImage: colorsAndTypography.palette.rainbow.main,
						borderImageSlice: 1,
					}
				}
			},
			MuiCardContent: {
				styleOverrides: {
					root: {
						background: 'unset',
						padding: 'unset',
						'&:last-child': {
							paddingBottom: 'unset'
						}
					},
				},
			},
			MuiButton: {
				variants: [
					{
						props: {
							variant: 'showaction'
						},
						style: {
							paddingBottom: '9px',
							paddingTop: '9px',
							minHeight: 'fit-content',
							minWidth: '100%',
							borderRadius: '8px',
							fontSize: '14px',
							fontWeight: 500,
							lineHeight: '16px',
							background: colorsAndTypography.palette.showActionBtn.main,
							color: colorsAndTypography.palette.showActionBtnText.main,
							transition: 'all .2s ease',
							backfaceVisibility: 'hidden',
							'&:hover': {
								opacty: 0.3,
								color: colorsAndTypography.palette.showActionBtnText.main,
								background: colorsAndTypography.palette.showActionBtn.main,
								borderRadius: '4px',
							},
							textTransform: 'none',
							'&.Mui-disabled': {
								color: colorsAndTypography.palette.showActionBtnText.main,
								background: colorsAndTypography.palette.showActionBtn.main,
							},
						}
					},
					{
						props: {
							variant: 'max'
						},
						style: {
							padding: '1px 5px',
							minHeight: 'fit-content',
							minWidth: 'fit-content',
							borderRadius: '4px',
							fontSize: '11px',
							fontWeight: 700,
							lineHeight: '18px',
							background: colorsAndTypography.palette.primary.main,
							color: colorsAndTypography.palette.text.secondary,
							transition: 'all .2s ease',
							backfaceVisibility: 'hidden',
							'&:hover': {
								opacity: 0.5,
								color: colorsAndTypography.palette.text.secondary,
								background: colorsAndTypography.palette.primary.main,
								borderRadius: '4px',
							},
							textTransform: 'none',
							'&.Mui-disabled': {
								opacity: 0.1,
								color: colorsAndTypography.palette.text.secondary,
								background: colorsAndTypography.palette.primary.main,
							},
						}
					},
					{
						props: {
							variant: 'collateral'
						},
						style: {
							padding: '0px',
							minHeight: '67px',
							minWidth: '67px',
							borderRadius: '50%',
							fontSize: '16px',
							fontWeight: 500,
							color: colorsAndTypography.palette.text.primary,
							background: colorsAndTypography.palette.noIcon.main,
							transition: 'all .2s ease',
							backfaceVisibility: 'hidden',
							'&:hover': {
								color: colorsAndTypography.palette.text.primary,
								background: colorsAndTypography.palette.noIcon.main,
								borderRadius: '50%'
							},
							textTransform: 'none',
							'&.Mui-disabled': {
								color: colorsAndTypography.palette.text.primary,
								background: colorsAndTypography.palette.noIcon.main,
							},
						}
					},
					{
						props: {
							variant: 'transparent'
						},
						style: {
							minHeight: '32px',
							minWidth: '90px',
							fontSize: '16px',
							fontWeight: 500,
							lineHeight: '20px',
							color: colorsAndTypography.palette.text.secondary,
							background: colorsAndTypography.palette.black.main,
							transition: 'all .2s ease',
							backfaceVisibility: 'hidden',
							'&:hover': {
								color: colorsAndTypography.palette.text.secondary,
								background: colorsAndTypography.palette.black.main,
							},
							textTransform: 'none',
							'&.Mui-disabled': {
								color: colorsAndTypography.palette.text.secondary,
								background: colorsAndTypography.palette.black.main,
							},
						}
					},
					{
						props: {
							variant: 'sort'
						},
						style: {
							minHeight: '32px',
							minWidth: '90px',
							fontSize: '16px',
							fontWeight: 500,
							lineHeight: '20px',
							background: 'transparent',
							border: 'transparent',
							color: 'inherit',
							transition: 'all .2s ease',
							backfaceVisibility: 'hidden',
							'&:hover': {
								opacity: 0.5,
								color: 'inherit',
								background: 'transparent',
							},
							textTransform: 'none',
							'&.Mui-disabled': {
								opacity: 0.5,
								color: 'inherit',
								background: 'transparent',
							},
						}
					},
					{
						props: {
							variant: 'white'
						},
						style: {
							minHeight: '32px',
							minWidth: '90px',
							fontSize: '14px',
							fontWeight: 700,
							lineHeight: '18px',
							background: colorsAndTypography.palette.white.main,
							border: `1px solid ${colorsAndTypography.palette.white.main}`,
							color: colorsAndTypography.palette.primary.main,
							transition: 'all .2s ease',
							backfaceVisibility: 'hidden',
							'&:hover': {
								opacity: 0.9,
								background: colorsAndTypography.palette.white.main,
								border: `1px solid ${colorsAndTypography.palette.white.main}`,
								color: colorsAndTypography.palette.primary.main,
							},
							textTransform: 'none',
							'&.Mui-disabled': {
								opacity: 0.9,
								background: colorsAndTypography.palette.white.main,
								border: `1px solid ${colorsAndTypography.palette.white.main}`,
								color: colorsAndTypography.palette.primary.main,
							},
						}
					},
					{
						props: {
							variant: 'blue'
						},
						style: {
							minHeight: '32px',
							minWidth: '90px',
							fontSize: '16px',
							fontWeight: 700,
							lineHeight: '18px',
							color: colorsAndTypography.palette.text.secondary,
							background: colorsAndTypography.palette.primary.main,
							transition: 'all .2s ease',
							backfaceVisibility: 'hidden',
							'&:hover': {
								color: colorsAndTypography.palette.text.secondary,
								background: colorsAndTypography.palette.primary.main,
								opacity: 0.5
							},
							textTransform: 'none',
							'&.Mui-disabled': {
								opacity: 0.1,
								color: colorsAndTypography.palette.text.secondary,
								background: colorsAndTypography.palette.primary.main,
							},
						}
					},
					{
						props: {
							variant: 'back'
						},
						style: {
							minHeight: '32px',
							minWidth: '90px',
							fontSize: '14px',
							fontWeight: 700,
							lineHeight: '18px',
							color: colorsAndTypography.palette.text.primary,
							background: 'unset',
							transition: 'all .2s ease',
							backfaceVisibility: 'hidden',
							'&:hover': {
								color: colorsAndTypography.palette.text.primary,
								background: 'unset',
								opacity: 0.5
							},
							textTransform: 'none',
							'&.Mui-disabled': {
								opacity: 0.1,
								color: colorsAndTypography.palette.text.primary,
								background: 'unset',
							},
						}
					},
					{
						props: {
							variant: 'discord'
						},
						style: {
							minHeight: '32px',
							minWidth: '90px',
							fontSize: '14px',
							fontWeight: 700,
							lineHeight: '18px',
							color: '#FFFFFF',
							border: '1px solid #794DEC',
							background: 'linear-gradient(0deg, #0D0D0E 0%, #0D0D0E 100%), #FFF',
							borderRadius: '6px',
							boxShadow: '1px 2px 12px 0px rgba(0, 0, 0, 0.10)',
							transition: 'all .2s ease',
							backfaceVisibility: 'hidden',
							textTransform: 'none',
							'&:hover': {
								color: '#FFFFFF',
								border: '1px solid #794DEC',
								background: 'linear-gradient(0deg, #0D0D0E 0%, #0D0D0E 100%), #FFF',
								opacity: 0.5
							},
							'&.Mui-disabled': {
								opacity: 0.1,
								color: '#FFFFFF',
								border: '1px solid #794DEC',
								background: 'linear-gradient(0deg, #0D0D0E 0%, #0D0D0E 100%), #FFF',
							},
						}
					},
					{
						props: {
							variant: 'connect'
						},
						style: {
							minHeight: '32px',
							minWidth: '90px',
							fontSize: '14px',
							fontWeight: 700,
							lineHeight: '18px',
							color: colorsAndTypography.palette.text.secondary,
							background: colorsAndTypography.palette.primary.main,
							transition: 'all .2s ease',
							backfaceVisibility: 'hidden',
							boxShadow: `0px 0px 0px 4px rgba(49, 110, 255, 0.5)`,
							'&:hover': {
								color: colorsAndTypography.palette.text.secondary,
								background: colorsAndTypography.palette.primary.main,
								opacity: 0.5
							},
							textTransform: 'none',
							'&.Mui-disabled': {
								opacity: 0.1,
								color: colorsAndTypography.palette.text.secondary,
								background: colorsAndTypography.palette.primary.main,
							},
						}
					},
				],
				styleOverrides: {
					root: {
						'&.Mui-disabled': {
							opacity: '0.3 !important',
							color: colorsAndTypography.palette.text.secondary,
							border: '1px solid transparent',
						},
					},
					outlined: {
						minHeight: '32px',
						minWidth: '90px',
						fontSize: '14px',
						fontWeight: 700,
						lineHeight: '18px',
						color: colorsAndTypography.palette.primary.main,
						background: colorsAndTypography.palette.inputBg.main,
						border: `1px solid ${colorsAndTypography.palette.primary.main}`,
						transition: 'all .2s ease',
						backfaceVisibility: 'hidden',
						'&:hover': {
							opacity: 0.5,
							color: colorsAndTypography.palette.primary.main,
							background: colorsAndTypography.palette.inputBg.main,
							border: `1px solid ${colorsAndTypography.palette.primary.main}`,
						},
						textTransform: 'none',
						'&.Mui-disabled': {
							opacity: 0.3,
							color: colorsAndTypography.palette.primary.main,
							background: colorsAndTypography.palette.inputBg.main,
							border: `1px solid ${colorsAndTypography.palette.primary.main}`,
						},
						//'&::after': {
						//  content: '" "',
						//  borderRadius: '4px',
						//  borderBottom: '1px solid #000000',
						//  position: 'absolute',
						//  paddingTop: '50%',
						//  width: '103%',
						//}
					},
					text: {
						fontSize: '16px',
						fontWeight: 700,
						color: colorsAndTypography.palette.secondary.main,
						transition: 'all .2s ease',
						backfaceVisibility: 'hidden',
						'&:hover': {
							color: colorsAndTypography.palette.secondary.main,
							backgroundColor: 'unset'
						},
						textTransform: 'none',
						'&.Mui-disabled': {
							color: colorsAndTypography.palette.secondary.main,
						},
					},
					contained: {
						minHeight: '32px',
						minWidth: '90px',
						fontSize: '16px',
						fontWeight: 500,
						lineHeight: '20px',
						color: colorsAndTypography.palette.text.primary,
						transition: 'all .2s ease',
						backfaceVisibility: 'hidden',
						background: colorsAndTypography.palette.buttonBG.main,
						'&:hover': {
						},
						textTransform: 'none',
						'&.Mui-disabled': {
							opacity: 0.5,
						},
					}
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						borderRadius: '8px',
						position: 'relative',
						'& $notchedOutline': {
							border: `1px solid ${colorsAndTypography.palette.primary.main}`,
						},
						'&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
							borderColor: colorsAndTypography.palette.primary.main,
							// Reset on touch devices, it doesn't add specificity
							'@media (hover: none)': {
								border: `1px solid ${colorsAndTypography.palette.primary.main}`,
							},
						},
						'&$focused $notchedOutline': {
							borderColor: colorsAndTypography.palette.primary.main,
							borderWidth: 1,
						},
						'& label.Mui-focused': {
							border: `1px solid ${colorsAndTypography.palette.primary.main}`,
						},
						'& > .MuiOutlinedInput-notchedOutline': {
							border: 'unset'
						},
						'& input': {
							color: colorsAndTypography.palette.inputText.main,
							background: colorsAndTypography.palette.inputBg.main
						}
					},
					input: {
						padding: '8px 0px 8px 8px',
						borderRadius: '8px',
					}
				}
			},
			MuiInputBase: {
				styleOverrides: {
					root: {
						borderRadius: '8px',
						fontSize: '12px',
						fontWeight: 400,
						lineHeight: '18px',
						color: colorsAndTypography.palette.inputText.main,
						'& input': {
							color: colorsAndTypography.palette.inputText.main,
						},
						'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
							display: 'none',
						},
						'& input[type=number]': {
							MozAppearance: 'textfield',
						},
					},
					input: {
						padding: '8px 0px 8px 8px',
					}
				},
			},
			MuiInputLabel: {
				styleOverrides: {
					root: {
						color: colorsAndTypography.palette.inputText.main,
						focused: {
							color: colorsAndTypography.palette.inputText.main,
						},
					},
				},
			},
			MuiFormLabel: {
				styleOverrides: {
					root: {
						color: colorsAndTypography.palette.inputText.main,
						'& > label': {
							color: colorsAndTypography.palette.inputText.main,
						},
						'&$focused': {
							color: colorsAndTypography.palette.inputText.main
						}
					},
				},
			},
			MuiFormControl: {
				styleOverrides: {
					root: {
						color: colorsAndTypography.palette.inputText.main,
						'& > label,span': {
							color: colorsAndTypography.palette.inputText.main,
						},
					},
				},
			},
			MuiSwitch: {
				styleOverrides: {
					thumb: {}
				}
			},
			MuiListItemText: {
				styleOverrides: {
					root: {
						color: colorsAndTypography.palette.text.primary,
					}
				}
			},
			MuiTooltip: {
				styleOverrides: {
					tooltip: {
						color: colorsAndTypography.palette.text.secondary,
						fontSize: '14px',
						fontWeight: 500,
						lineHeight: '18px',
						background: colorsAndTypography.palette.secondary.main,
						padding: '4px 8px',
						border: `1px solid ${colorsAndTypography.palette.secondary.main}`,
					},
					arrow: {
						'&:before': {
							border: `1px solid ${colorsAndTypography.palette.secondary.main}`,
						},
						color: colorsAndTypography.palette.secondary.main,
					},
				},
			},
			MuiTab: {
				styleOverrides: {
					root: {}
				}
			},
			MuiTabs: {
				styleOverrides: {
					root: {
						'& > button': {
							minHeight: 'unset'
						}
					},
					indicator: {
						display: 'none',
						width: ['100%', 'auto'],
						padding: '0px',
						backgroundColor: colorsAndTypography.palette.black.main
					},
				}
			},
			MuiDivider: {
				styleOverrides: {
					root: {
						padding: '0.5px',
						backgroundColor: 'unset',
						border: 'unset',
						borderColor: 'unset'
					},
				}
			},
			MuiSelect: {
				styleOverrides: {
					outlined: {
						display: 'flex',
						alignItems: 'center'
					},
					icon: {
						color: colorsAndTypography.palette.text.primary
					}
				}
			},
			MuiModal: {
				styleOverrides: {
					root: {
						'.MuiBackdrop-root': {
							background: colorsAndTypography.palette.modalBackdrop.main,
							backdropFilter: 'blur(20px)'
						},
					},
				},
			},
			MuiBackdrop: {
				styleOverrides: {
					root: {
					},
				}
			},
			MuiPopover: {
				styleOverrides: {
					root: {
						'.MuiBackdrop-root': {
							background: colorsAndTypography.palette.modalBackdrop.main,
							backdropFilter: 'blur(20px)'
						},
					},
				},
			},
			MuiTableContainer: {
				styleOverrides: {
					root: {
						boxShadow: 'unset'
					},
				},
			},
			MuiTableHead: {
				styleOverrides: {
					root: {
						boxShadow: 'unset',
						borderBottom: '1px solid #E2E7F0',
						'.MuiTableCell-head': {
							paddingBottom: '2px'
						}
					},
				},
			},
			MuiTableRow: {
				styleOverrides: {
					root: {
						boxShadow: 'unset',
						border: 'unset'
					},
				},
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						background: colorsAndTypography.palette.cardBg.main,
						paddingTop: '8px',
						paddingBottom: '8px',
						borderBottom: '0.5px solid #E2E7F0'
					},
				},
			},
			MuiLink: {
				styleOverrides: {
					root: {
						'&:hover': {
							opacity: 0.5,
							color: colorsAndTypography.palette.text.primary,
							textDecoration: 'none',
						},
						fontSize: 'inherit',
						fontWeight: 'inherit',
						textDecoration: 'none',
						transition: 'all .2s ease',
						color: 'inherit',
					},
				}
			},
			MuiToggleButton: {
				styleOverrides: {
				},
			},
			MuiChip: {
				styleOverrides: {
					label: {
						color: colorsAndTypography.palette.text.secondary
					},
					root: {
						'& > .MuiChip-deleteIcon': {
							transition: 'all .2s ease',
							color: colorsAndTypography.palette.text.secondary,
							':hover': {
								color: colorsAndTypography.palette.text.secondary,
								opacity: '0.6',
							}
						}
					}
				}
			},
			MuiCircularProgress: {
				styleOverrides: {
					root: {
						color: colorsAndTypography.palette.text.primary
					}
				}
			},
			MuiSlider: {
				styleOverrides: {
					root: {
						color: colorsAndTypography.palette.text.primary
					},
					markLabel: {
						color: colorsAndTypography.palette.text.primary,
						fontSize: '12px',
						lineHeight: '14px',
						fontWeight: 400
					}
				}
			}
		},
	}, colorsAndTypography)
}

export default function ThemeWrapper({ children }: React.PropsWithChildren<{}>) {
	const theme = useMemo(() => createAppTheme(), [])

	return (<MuiThemeProvider theme={theme}>
		{children}
	</MuiThemeProvider>)
}
