//import { Link, useMatch, useResolvedPath } from 'react-router-dom';
//import type { LinkProps } from "react-router-dom";
import { Tooltip } from '@mui/material';
import { Link as MuiLink } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

let pages = [
	{
		name: 'Accounts',
		url: '/accounts',
		disabled: false,
		icon: null
	},
	{
		name: 'Earn',
		url: '/earn',
		disabled: false,
		icon: null
	},
	{
		name: 'Rewards',
		url: '/rewards',
		disabled: false,
		icon: null
	},
	{
		name: 'Prisma',
		url: '/prisma',
		disabled: false,
		icon: null
	},
]

let links = [
	{
		name: 'PrismaLRT',
		url: '#prisma-lrt',
		disabled: false,
		icon: null
	},
	{
		name: 'How it works',
		url: '#how-it-works',
		disabled: false,
		icon: null
	},
	{
		name: 'LSTs!?',
		url: '#lsts',
		disabled: false,
		icon: null
	},
	{
		name: 'The Fam',
		url: '#the-fam',
		disabled: false,
		icon: null
	},
	{
		name: 'Values',
		url: '#values',
		disabled: false,
		icon: null
	},
	{
		name: 'FAQ',
		url: '#FAQ',
		disabled: false,
		icon: null
	}
]

//function ActiveLink({ children, to, ...props }: LinkProps) {
//    let resolved = useResolvedPath(to);
//    let match = useMatch({ path: resolved.pathname, end: false });
//
//    return (<Box sx={{
//            '& > a': {
//                textDecoration: 'none',
//                '&:hover': {
//                    background: 'unset'
//                },
//            }}
//        }>
//        <Link to={to} {...props}>
//            <Box sx={{ '& > button, span': {
//                fontWeight: match ? '700 !important' : 'inherit',
//                '&:hover': {
//                },
//                '&:hover::after': {
//                }
//            } }}>
//                {children}
//            </Box>
//        </Link>
//    </Box>);
//}


type BaseListItemButtonProps = {
	name: string;
	icon: any;
	disabled: boolean;
}

function BaseListItemButton({ name, icon, disabled }: BaseListItemButtonProps) {
	const button = <Box>
		<ListItemButton
			disableRipple
			disabled={disabled}
			sx={{
				minHeight: 48,
				justifyContent: 'center',
				px: 2.5,
			}}
		>
			<ListItemIcon
				sx={{
					minWidth: icon ? '32px' : '0',
					mr: 'auto',
					justifyContent: 'center',
				}}
			>
				{icon}
			</ListItemIcon>
			<ListItemText primary={name} />
		</ListItemButton>
	</Box>

	return (disabled ? <Tooltip arrow title={'Coming soon'} placement="bottom">
		{button}
	</Tooltip> : <>{button}</>)
}

type Props = {
	open: boolean;
}

export default function MenuItems({ open }: Props) {
	//<List sx={{ display: 'flex', padding: 'unset' }}>
	//        {pages.map((page) => (
	//            !page.disabled ? <ActiveLink to={page.url} key={page.name}>
	//                <BaseListItemButton
	//                    name={page.name} icon={page.icon} disabled={page.disabled}
	//                />
	//            </ActiveLink> : <BaseListItemButton key={page.name}
	//                name={page.name} icon={page.icon} disabled={page.disabled}
	//            />
	//        ))}
	//    </List>
	return (<>
		<List sx={{ display: 'flex', padding: 'unset', flexDirection: ['column', 'row'] }}>
			{links.map((page) => (
				<MuiLink key={page.url} href={page.url}>
					<BaseListItemButton
						name={page.name} icon={page.icon} disabled={page.disabled}
					/>
				</MuiLink>
			))}
		</List>
	</>);
}
