import { Box, Card, CardContent, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLandingPageContext } from "../api/useLandingPageContext";
import TokenIcon from "./TokenIcon";
import { CONFIG, STABLECOIN_SYMBOL } from "../globals";
import { DisplayNumber } from "./DisplayNumber";
import { useEffect, useState } from "react";
import Slider from "./Slider";

const cardTitle = {
    fontFamily: 'Epilogue',
    fontSize: '26px',
    fontWeight: 700,
    lineHeight: '38px',
    color: '#FFFFFF',
    marginBottom: '16px'
}

const cardContent = {
    fontSize: '20px',
    fontWeight: 300,
    lineHeight: '28px',
    color: '#FFFFFF',
    textAlign: 'left'
}

export default function HowItWorks() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const { keyedLSDSummary, troveManagerPrices } = useLandingPageContext()

    const [targetCR, setTargetCR] = useState(180)

    const [collateralAmount, setCollateralAmount] = useState(1.8)

    const [debtAmount, setDebtAmount] = useState(
        (collateralAmount * troveManagerPrices[Object.values(keyedLSDSummary)[0].trove_manager]) / (targetCR / 100)
    )

    useEffect(() => {
        setDebtAmount((collateralAmount * troveManagerPrices[Object.values(keyedLSDSummary)[0].trove_manager]) / (targetCR / 100))
    }, [targetCR, keyedLSDSummary, troveManagerPrices])

    return (<Grid container rowSpacing={'30px'} columnSpacing={'60px'}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography sx={{
                fontFamily: 'Epilogue',
                fontSize: '32px',
                fontWeight: 700,
                lineHeight: '38px',
                textAlign: 'center'
             }}>
                How does Prisma work?
            </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box sx={{ marginBottom: '40px' }}>
                <img
                    src="./prisma-mint-icon-dark-bg.svg"
                    width={isMobile ? '100px' : '50px'}
                    height={isMobile ? '100px' : '50px'}
                    style={isMobile ? {
                        margin: 'auto',
                        width: '100%'
                    } : {}}
                />
                <Typography sx={{...cardTitle, textAlign: ['center', 'left'] }}>
                    Mint
                </Typography>
                <Typography sx={{...cardContent, textAlign: ['center', 'left'] }}>
                    Choose your favorite LST and deposit it as collateral to mint mkUSD.
                </Typography>
            </Box>
            <Card sx={{
                paddingBottom: '24px',
                position: 'relative',
                background: 'linear-gradient(218deg, #25252A 0%, rgba(37, 37, 42, 0.75) 98.96%)',
                '&::before': {
                    transition: 'all 0.5s ease',
                    zIndex: 1,
                    content: '" "',
                    position: 'absolute',
                    top: '0px',
                    right: '10px',
                    width: '175px',
                    height: '175px',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: '175px',
                    backgroundImage: `url('/a.graphic}.svg')`,
                },
            }}>
                <CardContent sx={{ paddingX: '24px', paddingY: '24px' }}>
                    <Box sx={{
                        marginBottom: '12px',
                        '& > div > p': {
                            fontSize: '14px',
                            fontWeight: 500,
                            marginBottom: '12px'
                        },
                        '& > div > .MuiSlider-root': {
                            width: '80%',
                            marginLeft: '10%',
                            marginRight: '10%'
                        }
                    }}>
                        <Slider title={`Collateral Ratio of ${targetCR}%`} i={targetCR} change={setTargetCR} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 700, lineHeight: '30px' }}>
                            <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <TokenIcon address={CONFIG.addresses.DebtToken} width="30" height="30" />&nbsp;{STABLECOIN_SYMBOL}
                            </span>
                        </Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: 700, lineHeight: '30px' }}>
                            <DisplayNumber value={debtAmount} />
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 700, lineHeight: '30px' }}>
                            <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <TokenIcon address={Object.values(keyedLSDSummary)[0].token} width="30" height="30" />&nbsp;{Object.values(keyedLSDSummary)[0].symbol} Collateral
                            </span>
                        </Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: 700, lineHeight: '30px' }}>
                            <DisplayNumber value={collateralAmount} />
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box sx={{ marginBottom: '32px' }}>
                <img
                    src="./prisma-earn-icon-dark-bg.svg"
                    width={isMobile ? '100px' : '50px'}
                    height={isMobile ? '100px' : '50px'}
                    style={isMobile ? {
                        margin: 'auto',
                        width: '100%'
                    } : {}}
                />
                <Typography sx={{...cardTitle, textAlign: ['center', 'left'] }}>
                    Earn 
                </Typography>
                <Typography sx={{...cardContent, textAlign: ['center', 'left'] }}>
                    Earn yield on your mkUSD’s via Prisma’s Stability Pool or on other DeFi apps such as Curve and Convex.
                </Typography>
            </Box>
            <Box>
                <img
                    src="./prisma-vote-icon-dark-bg.svg"
                    width={isMobile ? '100px' : '50px'}
                    height={isMobile ? '100px' : '50px'}
                    style={isMobile ? {
                        margin: 'auto',
                        width: '100%'
                    } : {}}
                />
                <Typography sx={{...cardTitle, textAlign: ['center', 'left'] }}>
                    Vote    
                </Typography>
                <Typography sx={{...cardContent, textAlign: ['center', 'left'] }}>
                    Earn PRISMA for minting mkUSD, maintaining an active loan or depositing into the Stability Pool. Lock this PRISMA to direct emissions and take advantage of additional incentives.
                </Typography>
            </Box>
        </Grid>
    </Grid>)
}