import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as PrismaLRT } from './../graphics/prisma-lrt.svg'
import { ReactComponent as UltraIcon } from './../graphics/ultra.svg'
import { ReactComponent as EtherFiIcon } from './../graphics/etherfi.svg'
import { ReactComponent as KelpDaoIcon } from './../graphics/kelpdao.svg'
import { ReactComponent as RenzoIcon } from './../graphics/renzo.svg'
import { ReactComponent as SwellIcon } from './../graphics/swell.svg'
import OpenApp from "./OpenApp";

export default function PrismaLRTSection() {
    return (<Grid container rowSpacing={'30px'} columnSpacing={'60px'}>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ paddingBottom: ['16px', '50px'] }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography sx={{
                    fontSize: '24px',
                    fontWeight: 500,
                    lineHeight: '28px',
                    textAlign: 'center',
                    marginBottom: '16px'
                }}>
                    Introducing
                </Typography>
                <PrismaLRT width='300px' height='64px' />
                <Typography sx={{
                    fontSize: '24px',
                    fontWeight: 400,
                    lineHeight: '28px',
                    textAlign: 'center',
                    marginTop: '20px'
                }}>
                    Unlock the full potential of <span style={{ fontWeight: 700 }}>LRTs</span>!
                </Typography>
            </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography sx={{
                fontSize: '32px',
                fontWeight: 300,
                lineHeight: '44px', 
                textAlign: ['center', 'left'],
                marginBottom: '32px'
            }}>
                <span style={{ fontWeight: 700 }}>Prisma LRT</span> by Prisma Finance introduces vaults collateralized with Liquid Restaking Tokens (<span style={{ fontWeight: 700 }}>LRTs</span>)
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px', justifyContent: ['center', 'flex-start']  }}>
                <UltraIcon width='54px' height='55px' />
                <Typography sx={{
                    fontFamily: 'Epilogue',
                    fontSize: '26px',
                    fontWeight: 700,
                    lineHeight: '38px',
                    marginLeft: '10px'
                }}>
                    Mint ULTRA
                </Typography>
            </Box>
            <Typography sx={{ fontSize: '20px', fontWeight: 300, lineHeight: '28px', marginBottom: '18px', textAlign: ['center', 'left'], }}>
                The native stablecoin for PrismaLRT, pegged to USD. Fees from vault’s collateralized with LRTs will flow back to PRISMA lockers.
            </Typography>
            <Box sx={{ display: ['none', 'flex'], flexDirection: 'row', alignItems: 'center', justifyContent: ['center', 'flex-start']  }}>
                <OpenApp />
            </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography sx={{
                fontFamily: 'Epilogue',
                fontSize: '26px',
                fontWeight: 700,
                lineHeight: '38px',
                textAlign: 'center',
                marginBottom: '24px'
            }}>
                Supported LRTs
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', marginBottom: '24px' }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <EtherFiIcon width='58px' height='56px' />
                    <Typography sx={{
                        fontFamily: 'Epilogue',
                        fontSize: '24px',
                        fontWeight: 600,
                        lineHeight: '24px',
                        textAlign: 'center',
                        marginTop: '12px',
                        marginBottom: '8px',
                    }}>
                        weETH
                    </Typography>
                    <Typography sx={{
                        fontFamily: 'Epilogue',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '12px',
                        textAlign: 'center',
                    }}>
                        (ether.fi)
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <KelpDaoIcon width='58px' height='56px' />
                    <Typography sx={{
                        fontFamily: 'Epilogue',
                        fontSize: '24px',
                        fontWeight: 600,
                        lineHeight: '24px',
                        textAlign: 'center',
                        marginTop: '12px',
                        marginBottom: '8px',
                    }}>
                        rsETH
                    </Typography>
                    <Typography sx={{
                        fontFamily: 'Epilogue',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '12px',
                        textAlign: 'center',
                    }}>
                        (kelpDAO)
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <RenzoIcon width='58px' height='56px' />
                    <Typography sx={{
                        fontFamily: 'Epilogue',
                        fontSize: '24px',
                        fontWeight: 600,
                        lineHeight: '24px',
                        textAlign: 'center',
                        marginTop: '12px',
                        marginBottom: '8px',
                    }}>
                        ezETH
                    </Typography>
                    <Typography sx={{
                        fontFamily: 'Epilogue',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '12px',
                        textAlign: 'center',
                    }}>
                        (Renzo)
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    opacity: 0.2
                }}>
                    <SwellIcon width='58px' height='56px' />
                    <Typography sx={{
                        fontFamily: 'Epilogue',
                        fontSize: '24px',
                        fontWeight: 600,
                        lineHeight: '24px',
                        textAlign: 'center',
                        marginTop: '12px',
                        marginBottom: '8px',
                    }}>
                        rswETH
                    </Typography>
                    <Typography sx={{
                        fontFamily: 'Epilogue',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '12px',
                        textAlign: 'center',
                    }}>
                        (Swell)
                    </Typography>
                </Box>
            </Box>
            <Typography sx={{ fontSize: '16px', fontWeight: 300, lineHeight: '22px', textAlign: 'center', marginBottom: '32px' }}>
                More LRTs will be added over time, pending DAO approval
            </Typography>
            <Typography sx={{
                fontSize: '20px',
                fontWeight: 300,
                lineHeight: '28px',
                textAlign: 'center'
            }}>
                Use these collaterals to mint <span style={{ fontWeight: 700 }}>ULTRA</span>.<br /> Because of the native yield of liquid restaking tokens, the value of the collateral is always increasing!
            </Typography>
            <Box sx={{ display: ['flex', 'none'], flexDirection: 'row', alignItems: 'center', justifyContent: ['center', 'flex-start'], marginTop: '24px' }}>
                <OpenApp />
            </Box>
        </Grid>
    </Grid>)
}