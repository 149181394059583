//import prod from "./config/prod.json";
import dev from "./config/dev.json";
import mainnet from "./config/mainnet.json";
import { CollateralConfig, CollateralConfigDict } from "./types"

export const PRELAUNCH = process.env.REACT_APP_PRELAUNCH === 'true'

// Feature Flags
export const EMISSIONS_FEATURE = process.env.REACT_APP_EMISSIONS_FEATURE === 'true'
export const PROPOSAL_FEATURE = process.env.REACT_APP_PROPOSAL_FEATURE === 'true'

export const APP_URL = 'https://app.prismafinance.com'

export const ETHERSCAN = 'https://goerli.etherscan.io/'
export const DOCS_DOMAIN = 'https://docs.prismafinance.com'
export const DISCORD = 'https://discord.gg/prismafinance'
export const TWITTER = 'https://twitter.com/PrismaFi'
export const GITHUB = 'https://github.com/prisma-fi'
export const TELEGRAM = 'https://t.me/PrismaFinance'

export const MCR_RATIO = 1.2
export const MCR_PCT = 120
export const CCR_RATIO = 1.5
export const CCR_PCT = 150
export const MINIMUM_DEBT = 1800
export const GAS_COMPENSATION = 200
export const MIN_MINT_FEE = 0.005 // 0.5%
export const MAX_MINT_FEE = 0.05 // 5%
export const MIN_REDEMPTION_FEE = 0.005 // 0.5%
export const MAX_REDEMPTION_FEE = 1 // 100%

export const COLLATERAL_COUNT = 3

const loadConfig = (conf: string | undefined) => {
    switch(conf) {
        case 'dev':
            return dev;
        case 'mainnet':
            return mainnet;
        default:
            throw Error('no config')
    }
}

export const CONFIG = loadConfig(process.env.REACT_APP_CONFIG);

export const CHAIN = "Ethereum Mainnet"
export const CHAIN_ID = '0x1' // TODO: load from config

export const PRISMA_SYMBOL = 'PRISMA' 
export const PRISMA_ADDRESS = CONFIG.addresses.PrismaToken
export const STABLECOIN_SYMBOL = 'mkUSD'
export const STABLECOIN_ADDRESS = CONFIG.addresses.DebtToken

export const BASE_API = CONFIG.apiUrl
export const MAINNET_RPC = CONFIG.rpcUrl
export const SUBGRAPH_URL = CONFIG.subgraphUrl

export const MAINNET_MULTICALL = CONFIG.addresses.multicall

export const COLLATERALS: CollateralConfigDict = CONFIG.addresses.tranches

export const COLLATERAL_ADDRESSES: string[] = Object.keys(COLLATERALS)

export const SYMBOL_TO_COLLATERAL: { [key: string]: string } = {}

export const TM_TO_COLLATERAL: { [key: string]: CollateralConfig } = {}

export const AUDIT1 = 'https://github.com/prisma-fi/audits/blob/main/audit-mixbytes.pdf'
export const AUDIT2 = 'https://github.com/prisma-fi/audits/blob/main/audit-zellic.pdf'
export const AUDIT3 = 'https://github.com/prisma-fi/audits/blob/main/audit-nomoi.pdf'

export const COLLATERAL_TO_CURVE: { [key: string]: string } = {
    "0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0": "https://curve.fi/#/ethereum/pools/steth/swap",
    "0xae78736Cd615f374D3085123A210448E74Fc6393": "https://curve.fi/#/ethereum/pools/factory-crypto-210/swap",
    "0xBe9895146f7AF43049ca1c1AE358B0541Ea49704": "https://curve.fi/#/ethereum/pools/factory-crypto-91/swap",
    "0xac3E018457B222d93114458476f3E3416Abbe38F": "https://curve.fi/#/ethereum/pools/frxeth/swap"
}

export const trancheTypeIndicator = (type: number) => {
    switch (type) {
      case 1:
          return ""
      case 2:
          return "-A"
      case 3:
          return "-B"
      default:
          return ""
    }
  }