import { useState } from 'react';
import { Box, Button, Card, CardContent, Typography, Link as MuiLink } from '@mui/material';
import { APP_URL, CCR_RATIO, COLLATERAL_TO_CURVE, EMISSIONS_FEATURE, STABLECOIN_SYMBOL, trancheTypeIndicator } from '../../globals';
import { LSDSummary, ReceiverWeight } from '../../types';
import { DisplayNumber } from '../DisplayNumber';
import { DisplayShortNumber } from '../DisplayShortNumber';
import Link from '../Link';
import APRModal from '../APRModal';
import CollateralStats from './CollateralStats';
import AprDisplay from './AprDisplay';
import TrancheTypeIndicator from '../TrancheTypeIndicator';

type Props = {
    lsd: LSDSummary
    weights: ReceiverWeight[]
}

function CollateralOverview({ lsd, weights }: Props) {
    const [open, setOpen] = useState(false)
    const handleOpenAprModal = () => setOpen(true)

    const stats = [
        {
            help: 'TVL (or Total Value Locked) is the total value of all the assets in this pool.',
            title: 'Total Value Locked',
            value: <>$<DisplayShortNumber value={
                lsd.tvlData ?
                    lsd.tvlData.collUSD : 0
            } /></>,
        },
        {
            help: `This is the maximum amount of ${STABLECOIN_SYMBOL} that can be minted with this collateral.`,
            title: `Minted ${STABLECOIN_SYMBOL}`,
            value: <>{lsd.maxSystemDebt === 0 ? <><DisplayShortNumber value={
                    lsd.tvlData ?
                        lsd.tvlData.debt : 0
                } />/∞</> : <>
                <DisplayShortNumber value={
                    lsd.tvlData ?
                        lsd.tvlData.debt : 0
                } />/<DisplayShortNumber value={lsd.maxSystemDebt} />
            </>}</>,
        },
        {
            help: `MCR (or Minimum Collateral Ratio) is the minimum ratio between collateral and debt a vault must maintain to avoid liquidation. Please note in recovery mode the MCR is ${CCR_RATIO}.`,
            title: `MCR`,
            value: <><DisplayNumber value={lsd.minimumCollateralRatio.toNumber()*100} precision={0} />%</>,
        },
        {
            help: ``,
            title: `Mint Fee`,
            value: <><DisplayNumber value={lsd.mintFee.toNumber()*100} precision={2} />%</>,
        },
        {
            help: `The fee that accrues over time on outstanding debt. It is charged in mkUSD.`,
            title: `Borrow Interest Rate`,
            value: <><DisplayNumber value={lsd.interestRate} precision={2} />%</>,
        },
        {
            help: `At time of redemption the redemption fee is paid back to the redeemed vault owner.`,
            title: `Redemption Rebate`,
            value: <><DisplayNumber value={lsd.redemptionRebate !== -1 ? lsd.redemptionRebate : 0} precision={2} />%</>,
        },
        {
            help: undefined,
            title: 'Staked Ether APR',
            value: <><DisplayNumber value={lsd.aprData.eth} />%</>,
        },
    ]

    return <>
        <Box sx={{ position: 'relative' }}>
            <Card
                sx={{
                    position: 'relative',
                    opacity: 0.9,
                    zIndex: 1,
                    transition: 'all 0.5s ease',
                    overflow: 'visible',
                    paddingBottom: '6px',
                    '&:hover': {
                        zIndex: 4,
                        opacity: 1,
                        boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`
                    },
                    '&:hover::before': {
                        zIndex: 3,
                        top: '-20px',
                        opacity: 1,
                    },
                    '&::before': {
                        transition: 'all 0.5s ease',
                        zIndex: 2,
                        content: '" "',
                        position: 'absolute',
                        top: '-10px',
                        left: '-35px',
                        width: '70px',
                        height: '70px',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: '70px',
                        backgroundImage: `url('/collaterals/${lsd.token}.svg')`,
                    },
                }}
            >
                <CardContent>
                    <Box className='collateral-card-header'>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography sx={{
                            paddingTop: '5px',
                            fontSize: '24px',
                            fontWeight: 600,
                            fontFamily: `Epilogue`,
                            lineHeight: '25px',
                            marginRight: '4px'
                        }}>
                            {lsd.symbol}
                        </Typography>
                        <TrancheTypeIndicator trancheType={lsd.type} />
                    </Box>
                    </Box>
                    <CollateralStats stats={stats} />
                    {EMISSIONS_FEATURE ? <Box onClick={handleOpenAprModal} sx={{ '&:hover': { cursor: 'pointer' } }}>
                        <AprDisplay lsd={lsd} weights={weights} />
                    </Box> : <></>}
                    <Box sx={{ marginY: '12px', paddingX: '12px' }}>
                        <MuiLink href={`${APP_URL}/vaults/select/${lsd.trove_manager}`} target="__blank" rel="noreferrer">
                            <Button fullWidth variant={'blue'}>
                                Choose {lsd.symbol}{trancheTypeIndicator(lsd.type)}
                            </Button>
                        </MuiLink>
                    </Box>
                </CardContent>
            </Card>
        </Box>
        <APRModal lsd={lsd} open={open} setOpen={setOpen} />
    </>
}

export default CollateralOverview;
